@charset "utf-8";

/*! contents - pc */

/*! 브랜드 공통 */
.brand {
  .sec {
    padding: 100px 0;
    overflow: hidden;
  }

  // 주석 공통
  .ref-txt {
    position: relative;
    font-style: normal;
    line-height: 1.5;
    // 서브타이틀
    top: -12px;
    left: -4px;
    font-size: 12px;

    // 대타이틀
    &.big {
      top: -39px;
      left: -10px;
      font-size: 16px;
    }
  }

  .title-wrap {
    text-align: center;
    .title {
      margin-bottom: 17px;
      span {
        display: block;
        // font-family: $NotoSansL;
        @include notoSansL();
        font-size: $fontSizePc + 36;
        line-height: 1.3;
        em {
          @include notoSansB();
          font-size: $fontSizePc + 36;
          line-height: 1.3;
          color: #238754;
        }
      }
    }
    .sub-tit {
      display: block !important;
      max-width: 670px;
      margin: auto;
      @include text-overflow-multi(2);
      // font-family: $NotoSansM;
      @include notoSansM();
      font-size: $fontSizePc + 14;
    }
    .tit {
      margin: 20px 0 38px;
    }
    .sub-txt {
      // max-width: 800px;
      margin: auto;
      span {
        display: block;
        margin-bottom: 10px;
        @include text-overflow-multi(2);
        font-size: $fontSizePc + 6;
        // .ref-txt {
        //   font-size: 12px;
        //   position: relative;
        //   top: -10px;
        //   left: -4px;
        //   font-style: normal;
        // }
      }
    }
  }
  .swiper-pagination {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: get-vw(30px);
    width: 100%;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    .swiper-pagination-bullet {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 7px;
      border-radius: 100%;
      background-color: #cccccc;
      cursor: pointer;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #238754;
    }
  }
  #popup, .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    transform: translate(-50%, -50%);
    z-index: 100;
    .popup-wrap {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 33.333333%;
      height: auto;
      transform: translate(-50%, -50%);
    }
    .popup-close {
      position: absolute;
      top: -49px;
      right: 0;
      .ico-close {
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: url(../../images/common/ico_close_white.png);
        background-position: center;
        background-repeat: no-repeat;
        background: cover;
      }
    }
    &.youtube {
      .popup-wrap {
        width: 978px;
        height: 550px;
      }
    }
    &.img-pop {
      .popup-wrap {
        max-width: 980px;
        max-height: 720px;
        text-align: right;
        img {
          max-width: 100%;
        }
      }
    }
    &.pull-popup{
      overflow-y: scroll;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      transform: none;
      z-index: 100;
      background-image: url(../../images/common/popup_bg01.png);
      background-size: 100% 100%;
      .popup-inner{position: relative; width: 1200px;margin: 0 auto 120px;}
      .popup-header{
        margin-top: 120px;
        text-align: center;
        .since-txt{
          // font-family: $NotoSansM;
          @include notoSansM();
          font-size: $fontSizePc + 6;
          color: #666;
        }
        .tit{
          margin-top:15px;
          // font-family: $NotoSansL;
          @include notoSansL();
          font-size: $fontSizePc + 36;
          color: #222;
          em{
            @include notoSansB();
          }
        }
      }
      .popup-content{
        .column-group{display: table;table-layout: fixed;margin-top: get-vw(85px);}
        .column{
          display: table-cell;
          width: 550px;
          .txt{
            font-family: $NotoSansDL;
            font-size: $fontSizePc + 2;
            color: #222;
            word-break: keep-all;
            line-height: 1.5;
          }
          .tag-area{margin-top:get-vw(60px);}
          .tag{
            display: inline-block;
            margin:0 10px 10px 0;
            padding: 5px 20px;
            border: 1px solid #fff;
            border-radius: 25px;
            background: #fff;
            @include font($NotoSansDL, $fontSizePc, 1.5, #777);
            box-sizing: border-box;
          }
          &:first-child{padding-right: 100px;vertical-align: top;}
          .gallery-view-img{
            background: #fff;
            .img-item{
              text-align: center;
              .tit{@include font($NotoSansDL, $fontSizePc + 4, 1.5, #222);}
              img{margin-top:get-vw(30px);}
            }
          }
          .gallery-btn{
            display: flex;
            justify-content: center;
            margin-top: 10px;
            font-size: 0;
            .btn-item{
              position: relative;
              display: inline-block;
              width: calc(25% - 8px);
              margin-left: 10px;
              margin-bottom: get-vw(10px);
              transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1) all;
              cursor: pointer;
              &:nth-child(4n+1){margin-left:get-vw-mo(0px);}
              button{
                width: 100%;
                padding: 0;
                img{width: 100%;}
              }
              &.on,&:hover{
                button{
                  &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: 0.3;
                  }
                }
              }
              // &:hover{
              //   button{
              //     &::after{
              //       content: "";
              //       position: absolute;
              //       top: 0;
              //       left: 0;
              //       width: 100%;
              //       height: 100%;
              //       background: #000;
              //       opacity: 0.3;
              //     }
              //   }
              // }
            }
          }
        }
        .desc-area{
          text-align: center;
          margin-top: get-vw(50px);
          padding: get-vw(30px);
          background: #e4e8ec;
        }
      }
      .popup-close{
        top: -30px;
        .ico-close{
          background-image: url(../../images/common/ico_close_black.png);
        }
      }
    }
  }
  .ft-txt {
    padding: 20px 0;
    color: #fff;
    border-bottom: 1px solid rgba(204,204,204,0.1);
    background-color: #666666;
    .noti-area {
      .mgt {
        margin-top: 8px;
      }
      span {
        display: block;
        font-size: 12px;
        line-height: 1.7;
      }

      .point-txt {
        padding-left: 10px;
        text-indent: -10px;
      }

      .sub-txt {
        color:#aaa;
      }

      .desc{margin-top: 15px;}
    }


  }
  .reference{
    padding: 20px 0 !important;
    background-color: #d8dcdf;
    p {
      margin-bottom: 8px;
      text-align: left;
      font-size: 13px;
    }
    span {
      display: block;
      padding-left: 14px;
      text-indent: -14px;
      text-align: left;
      font-size: 13px;
      line-height: 1.2;
      color: #666;
      a {
        font-size: 14px;
        color: #666;
      }
    }
  }
}

/*! 브랜드스토리 공통 */
.story {
  .sec {
    padding: 100px 0;
    text-align: center;
    overflow: hidden;
  }
  .fix-indi {
    position: fixed;
    top: 220px;
    left: get-vw(50px);
    z-index: 10;

    button {
      cursor: pointer;
      position: relative;
      display: block;
      padding-left: 16px;
      margin-bottom: 20px;
      @include fontE($fontEng, 500);
      color: #999;
      transition: 0.2s ease all;
      &.on {
        color: #222;
        &:after {
          content: "";
          display: block;
          clear: both;
          position: absolute;
          top: 50%;
          left: 0;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          border-radius: 100%;
        }
      }
    }
    &:hover {
      color: #222;

    }
    &.change-other-color {
      button {
        color: rgba(255, 255, 255, 0.5);

        &:hover {
          color: #ffec70;
          &:after {
            background-color: #ffec70;
          }
        }

        &.on {
          color: #ffec70;
          &:after {
            background-color: #ffec70;
          }
        }
      }
    }
    &.change-white {
      button {
        color: rgba(255, 255, 255, 0.5);

        &:hover {
          color: #fff;
          &:after {
            background-color: #fff;
          }
        }

        &.on {
          color: #fff;
          &:after {
            background-color: #fff;
          }
        }
      }
    }
    // &.change-aqua {
    //   button {
    //     color: rgba(255, 255, 255, 0.5);

    //     &:hover {
    //       color: #5d5ec3;
    //       &:after {
    //         background-color: #5d5ec3;
    //       }
    //     }

    //     &.on {
    //       color: #5d5ec3;
    //       &:after {
    //         background-color: #5d5ec3;
    //       }
    //     }
    //   }
    // }
  }
  .section05 {
    padding-bottom: 140px;
    // background-color: #f1f5f8;
    .title-wrap {
      margin-bottom: get-vw(60px);
      .sub-txt {
        margin-top: get-vw(30px);
      }
    }
    .media-cnt {
      position: relative;
      width: 940px;
      margin: auto;
      .thum-img {
        position: relative;
        width: 724px;
        height: 404px;
        margin: auto;
        cursor: pointer;
        img {
          width: 100%;
        }
        .btn-play {
          width: 102px;
          height: 102px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -51px;
          margin-left: -51px;
        }
        .txt {
          position: absolute;
          left: 30px;
          bottom: 30px;
          // font-family: $NotoSansM;
          @include notoSansM();
          font-size: $fontSizePc + 4;
          line-height: 1.3;
          color: #fff;
          z-index: 1;
        }
      }
      .pop-cnt {
        display: none;
      }
      .media-swiper {
        position: relative;
        margin: auto;
        width: 724px;
        height: 404px;
      }
      .swiper-pagination {
        bottom: -49px;
      }
      .swiper-button-next,
      .swiper-button-prev {
        width: get-vw(66px);
        height: get-vw(66px);
        border-radius: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 50%;
        margin-top: get-vw(-33px);
        cursor: pointer;
      }
      .swiper-button-next {
        background-image: url(../../images/common/ico_next_type03.png);
        right: 0;
        left: auto;
      }
      .swiper-button-prev {
        background-image: url(../../images/common/ico_prev_type03.png);
        left: 0;
        right: auto;
      }
    }
  }
}

.ui-media-section {
  position: relative;

  &::after {
    content: "";
    clear: both;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    background-color: #f1f5f8;
    opacity: 0;
    transition: 1.35s cubic-bezier(0.66, 0, 0.34, 1) all;
  }

  &.on {
    &::after {
      opacity: 1;
      height: 100%;
    }
  }
}


// 제품정보
.product-info {
  .mgt0 {margin-top: 0 !important;}
  .mgt-40 {margin-top: -40px !important;}
  .mgt-22 {
            margin-top: -22px !important;
          }
  .title-wrap {
    .sub-tit {
      display: block;
      margin-bottom: get-vw(17px);
      // font-family: $NotoSansM;
      @include notoSansM();
      font-size: $fontSizePc + 4;
      color: #666;
    }
  }
  .section01 {
    position: relative;
    padding-top: get-vw(120px);
    text-align: center;
    background: linear-gradient(#fff 65%, #f1f5f8 35%);
    .inner {
      overflow: hidden;
      max-width: get-vw(1340px);
    }
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
      position: absolute;
      top: 50%;
      width: get-vw(66px);
      height: get-vw(66px);
      margin-top: 17px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
      right: auto;
      background-image: url(../../images/common/ico_prev_type01.png);
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0;
      left: auto;
      background-image: url(../../images/common/ico_next_type01.png);
    }
    .swiper-pagination {
      display: none;
      bottom: get-vw(30px);
    }
    .product-swiper {
      padding-bottom: get-vw(87px);
      max-width: get-vw(1410px);
      width: 100%;
      margin: auto;
      .swiper-wrapper {
        position: relative;
        display: flex;
        .swiper-slide {
          position: relative;
          width: get-vw(394px);
          height: get-vw(394px);
          margin: auto;
          display: flex;
          flex-shrink: 0;
          border-radius: 100%;
          justify-content: center;
          cursor: pointer;
          .product-cnt {
            position: relative;
            align-self: center;
            z-index: 1;
            .img-box {
              display: flex;
              margin: auto;
              min-height: get-vw(190px);
              justify-content: center;
              img {
                align-self: center;
                width: 70%;
              }

            }
            .txt {
              display: block;
              margin-top: get-vw(40px);
              // font-family: $NotoSansM;
              @include notoSansM();
              font-size: $fontSizePc + 4;
              color: #999;
              em {
                display: block;
                font-size: $fontSizePc + 2;
                line-height: 1.6;
              }
              
            }
            &.vertical-box {
                img {
                  width: 63%;
                }
              }
          }
          .active-box {
            opacity: 0;
            position: absolute;
            // left: 50%;
            // transform: translateX(-50%) !important;
            width: get-vw(394px);
            height: 100%;
            max-width: 394px;
            border-radius: 100%;
          }
          &.on {
            .product-cnt {
              .txt {
                margin-top: get-vw(20px);
                // font-family: $NotoSansM;
                @include notoSansM();
                font-size: get-vw(24px);
                color: #fff;
                em {
                  display: block;
                  font-size:  get-vw(22px);
                  line-height: 1.6;
                }
              }

            }
            .active-box {
              opacity: 1;
              background-color: #238754;
              box-shadow: 15px 15px 15px 0 rgba(0, 0, 0, 0.1);
            }
          }
        }
        &.product2 {
          justify-content: center;
          .swiper-slide {
            margin: 0 18px;
            cursor: pointer;
            &.on {
              .active-box {
                opacity: 1;
              }
            }
            .product-cnt {
              .img-box {
                min-height: auto;
              }
              .txt {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

  }
  .section02 {
    padding-bottom: 100px;
    background-color: #f1f5f8;
    .product-cnt {
      display: none;
      &.on {
        display: block;
      }
    }
    .product-detail-wrap {
      background-color: #fff;
      .title {
        display: flex;
        padding: get-vw(42px) 0 get-vw(20px);
        border-bottom: 2px solid #222;
        .tit-box {
          align-self: center;
          .ico {
            display: inline-block;
            width: 24px;
            height: 27px;
            margin-right: 10px;
            // background: url(../../images/common/sprite_pc.png);
            // background-position: 0 -84px;
            @include sprite-custom($pc-ico-detail);
            vertical-align: middle;
          }
          .txt {
            display: inline-block;
            // font-family: $NotoSansM;
            @include notoSansM();
            font-size: $fontSizePc + 8;
            vertical-align: middle;
          }
        }
        a {
          display: block;
          margin-left: auto;
          padding: 12px 30px 13px;
          background-color: #238754;
          border-radius: 25px;
          .ico {
            display: inline-block;
            width: 15px;
            height: 20px;
            margin-right: 10px;
            vertical-align: middle;
            @include sprite-custom($pc-ico-down);
            // background: url(../../images/common/sprite_pc.png);
            // background-position: 0 -118px;
          }
          .txt {
            display: inline-block;
            vertical-align: middle;
            color: #fff;
          }
        }
      }
      .detail-wrap {
        .swiper-list {
          display: flex;
          margin-top: 14px;
          border-bottom: 1px dotted #ddd;
          .detail-list-wrap {
            width: 70%;
            .detail-list:last-child {
              border-bottom: 0;
            }
          }
          .detail-swiper {
            width: 30%;
            margin-top: 30px;
            position: relative;
            overflow: hidden;
            .swiper-wrapper {
              display: flex;
            }
            .swiper-slide {
              flex-shrink: 0;
              width: calc(100% - 32px);
              img {
                width: 100%;
              }
            }
            .swiper-pagination {
              opacity: 1;
              text-align: center;
              span {
                @include fontE($fontEng, 500)
                font-size:$fontSizePc + 4;
                color: #ccc;
                &.swiper-pagination-current {
                  color: #222;
                }
              }
            }
            .swiper-button-next,
            .swiper-button-prev {
              width: 9px;
              height: 14px;
              position: absolute;
              bottom: get-vw(35px);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            .swiper-button-prev,
            .swiper-container-rtl .swiper-button-next {
              left: 145px;
              right: auto;
              background-image: url(../../images/common/ico_prev_type04.png);
            }
            .swiper-button-next,
            .swiper-container-rtl .swiper-button-prev {
              right: 145px;
              left: auto;
              background-image: url(../../images/common/ico_next_type04.png);
            }
          }
        }
        .detail-list {
          padding: 40px 20px;
          border-bottom: 2px dotted #ddd;
          .detail-top {
            display: flex;
            > .ico {
              display: inline-block;
              width: 60px;
              height: 60px;
              margin-right: 20px;
              vertical-align: middle;
              background-repeat: no-repeat;
              background-position: center;
              &.ico01 {
                background-image: url(../../images/brand/ico_detail01.png);
                background-size: 60px 48px;
              }
              &.ico02 {
                background-image: url(../../images/brand/ico_detail02.png);
                background-size: 39px 52px;
              }
              &.ico03 {
                background-image: url(../../images/brand/ico_detail03.png);
                background-size: 47px 49px;
              }
              &.ico04 {
                background-image: url(../../images/brand/ico_detail04.png);
                background-size: 38px 55px;
              }
              &.ico05 {
                background-image: url(../../images/brand/ico_detail05.png);
                background-size: 28px 58px;
              }
              &.ico06 {
                background-image: url(../../images/brand/ico_detail06.png);
                background-size: 47px 42px;
              }
              &.ico07 {
                background-image: url(../../images/brand/ico_detail07.png);
                background-size: 47px 47px;
              }
              &.ico08 {
                background-image: url(../../images/brand/ico_detail08.png);
                background-size: 50px 50px;
              }
              &.ico09 {
                background-image: url(../../images/brand/ico_detail09.png);
                background-size: 37px 46px;
              }
              &.ico10 {
                background-image: url(../../images/brand/ico_detail10.png);
                background-size: 38px 43px;
              }
              &.ico11 {
                background-image: url(../../images/brand/ico_detail11.png);
                background-size: 37px 47px;
              }
              &.ico12 {
                background-image: url(../../images/brand/ico_detail12.png);
                background-size: 41px 40px;
              }
              &.ico13 {
                background-image: url(../../images/brand/ico_detail13.png);
                background-size: 47px 42px;
              }
              &.ico14 {
                background-image: url(../../images/brand/ico_detail14.png);
                background-size: 45px 50px;
              }
              &.ico15 {
                background-image: url(../../images/brand/ico_detail15.png);
                background-size: 45px 46px;
              }
              &.ico16 {
                background-image: url(../../images/brand/ico_detail16.png);
                background-size: 40px 51px;
              }
              &.ico17 {
                background-image: url(../../images/brand/ico_detail17.png);
                background-size: 46px 44px;
              }
            }
            .txt-box {
              width: calc(100% - 80px);
              .tit {
                display: block;
                margin-bottom: 5px;
                // font-family: $NotoSansM;
                @include notoSansM();
                font-size: $fontSizePc + 4;
                line-height: 1.6;
              }
              .txt {
                line-height: 1.5;
                color: #666;
                font-size: $fontSizePc + 2;
                .sub-tit {
                  display: block;
                  font-size: $fontSizePc + 2;
                }
                .sub-txt {
                  display: block;
                  word-break: keep-all;
                  color: #666;
                }
              }

              .img-txt{display: block; margin-top: 20px;}

              .product-box {
                display: table;
                width: 100%;
                margin-top: 20px;
                .product {
                  display: table-cell;
                  width: 33.333333%;
                  text-align: center;
                  img {
                    display: block;
                    margin: auto;
                  }
                  .txt {
                    display: block;
                    margin-top: 15px;
                    color: #666;
                    font-size: $fontSizePc + 2;
                    letter-spacing: -1px;
                  }
                }
                &.type02 {
                  .product {
                    width: 25%;
                  }
                }
              }
            }
          }
          .img-txt {
            display: block;
            margin-top: 30px;
          }
          .desc-box{
            display: block;
            margin-top:30px;
            span{
              display: block;
              word-break: keep-all;
              font-size: $fontSizePc;
              line-height: 1.6;
              color: #222;}
            .desc-t{color: #222;margin-bottom: 18px;}
            .desc{
              position: relative;
              padding-left: 16px;
              line-height: 1.7;
              &::before{
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 4px;
                height: 4px;
                margin-top: -2px;
                background: #666;
              }
            }
            &.mt-18{margin-top: 18px;}
          }
          .desc-box-type02 {
            .desc {
              word-break: keep-all;
              font-size: $fontSizePc + 2;
              line-height: 1.6;
              color: #666;
            }
          }
          .detail-btm {
            margin-left: 80px;
            .box-wrap {
              display: flex;
              width: 100%;
              margin-top: 16px;
              background-color: #f1f5f8;
              .cnt {
                flex: auto;
                width: 33.333333%;
                padding: 0 30px;
                margin: 20px 0;
                box-sizing: border-box;
                border-right: 1px solid #ddd;
                span {
                  display: block;
                  &.content {
                    word-break: keep-all;
                    line-height: 1.6;
                    color: #666;
                    em {
                      display: block;
                      font-size: 14px;
                      line-height: 1.6;
                      color: #666;
                    }
                  }
                  &.name {
                    .ico {
                      display: inline-block;
                      width: 27px;
                      height: 27px;
                      background-repeat: no-repeat;
                      background-position: center top;
                      vertical-align: middle;
                      &.ico01 {
                        margin-right: 9px;
                        background-size: 25px 24px;
                        background-image: url(../../images/brand/ico_detail03_01.png);
                      }
                      &.ico02 {
                        background-size: 15px 26px;
                        background-image: url(../../images/brand/ico_detail03_02.png);
                      }
                      &.ico03 {
                        background-size: 18px 26px;
                        background-image: url(../../images/brand/ico_detail03_03.png);
                      }
                      &.ico04 {
                        background-size: 24px 24px;
                        background-image: url(../../images/brand/ico_detail03_04.png);
                      }
                      &.ico05 {
                        background-size: 24px 24px;
                        background-image: url(../../images/brand/ico_detail03_05.png);
                      }
                    }
                    .txt {
                      display: inline-block;
                      vertical-align: middle;
                      color: #666;
                      line-height: 1.6;
                    }
                  }
                }
                &:last-child {
                  border-right: 0;
                }
              }
              &.type02{
                margin-top: 22px;
                padding: 22px 0;
                .cnt{
                  width: auto;
                  margin: 0;
                  padding: 0 0 0 20px ;
                }
              }
              &.type03{
                margin-top: 22px;
                padding: 22px 0;
                .cnt{
                  width: auto;
                  margin: 0;
                  padding: 0 0 0 20px ;
                }
              }
            }
            .sub-tit {
              display: block;
              margin-top: 30px;
              font-size: $fontSizePc + 2;
              line-height: 1.5;
            }
            .sub-txt {
              display: block;
              word-break: keep-all;
              color: #666;
              line-height: 1.5;
            }
          }
          .list {
            display: block;
            padding-left: 10px;
            text-indent: -10px;
            line-height: 1.8;
            word-break: keep-all;
            color: #666;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        table{
          &.table-type01{
            width: 100%;
            margin-top: 30px;
            thead{background: #f1f5f8;}
            th,td{
              text-align: center;
              padding: 15px 0;
              color: #666;
              font-family: $NotoSansDL;
              word-break: break-all;
              border: 1px solid #ddd;
              &:first-child{border-left: none;}
              &:last-child{border-right: none;}
            }
            th{
              // font-family: $NotoSansM;
              @include notoSansM();
              color: #222;
              border-top: 1px solid #888;
            }
          }
        }
      }
    }
    .precautions {
      padding: 30px 0;
      text-align: center;
      background-color: #666;
      .txt {
        color: #fff;
        line-height: 1.5;
      }
      .btn-download {
        display: block;
        width: 300px;
        margin: 20px auto 0;
        padding: 20px 0;
        background-color: #fff;
        border-radius: 30px;
        .txt {
          display: inline-block;
          // font-family: $NotoSansM;
          @include notoSansM();
          font-size: $fontSizePc + 4;
          line-height: 1.2;
          color: #222;
          vertical-align: middle;
        }
        .ico {
          display: inline-block;
          width: 18px;
          height: 20px;
          margin-right: 10px;
          // background: url(../../images/common/sprite_pc.png) no-repeat;
          // background-position: -95px -84px;
          @include sprite-custom($pc-ico-download-dark);
          vertical-align: middle;
        }
      }
    }
  }
  .section03 {
    padding: 60px 0;
    background-color: #333;
    .title-wrap {
      text-align: center;
      .related-tit {
        display: block;
        margin-bottom: 30px;
        // font-family: $NotoSansM;
        @include notoSansM();
        font-size: $fontSizePc + 14;
        line-height: 1.2;
        color: #fff;
      }
    }
    .related-swiper {
      overflow: hidden;
      width: 902px !important;
      margin: auto;
      .swiper-wrapper {
        display: flex;
        // justify-content: center;
        .swiper-slide {
          flex-shrink: 0;
          width: 282px !important;
          a {
            display: block;
          }
          .img-box {
            img {
              width: 100%;
            }
          }
          .txt-box {
            margin-top: 30px;
            span {
              display: block;
              text-align: center;
              &.tit {
                margin-bottom: 15px;
                // font-family: $NotoSansM;
                @include notoSansM();
                font-size: $fontSizePc + 4;
                color: #fff;
              }
              &.txt {
                color: #999;
                line-height: 1.6;
              }
            }
          }
        }
      }
      &.product2 {
        .swiper-wrapper {
          justify-content: center;
          .swiper-slide {
            & + .swiper-slide {
              margin-left: 30px;
            }
          }
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      width: get-vw(66px);
      height: get-vw(66px);
      margin-top: get-vw(-33px);
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
      right: auto;
      background-image: url(../../images/common/ico_prev_type02.png);
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0;
      left: auto;
      background-image: url(../../images/common/ico_next_type02.png);
    }
    .swiper-pagination {
      bottom: -50px;
    }
  }
}



// wide
@media (min-width: 1920px) {
  .story {
    .section05 {
      .media-cnt {
        position: relative;
        width: 940px;
        margin: auto;
        .swiper-button-next,
        .swiper-button-prev {
          width: 66px;
          height: 66px;
          margin-top: -33px;
        }
        .swiper-button-next {
          right: 0;
        }
        .swiper-button-prev {
          left: 0;
        }
      }
    }
  }
  //제품정보
  .product-info {
    .section01 {
      padding-top: 120px;
      .inner {
        overflow: hidden;
        max-width: 1340px;
      }
      .swiper-button-next,
      .swiper-button-prev {
        width:66px;
        height: 66px;
        margin-top: 17px;
      }
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 0;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 0;
      }
      .product-swiper {
        padding-bottom: 87px;
        .swiper-wrapper {
          .swiper-slide {
            width: 394px;
            height: 394px;
            .product-cnt {
              .img-box {
                min-height: 190px;
                img {
                }
              }
              .txt {
                margin-top: 40px;
                font-size: 20px;
              }
            }
            &.on {
              .product-cnt {
                .txt {
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
    }
    .section03 {
      .swiper-button-next,
      .swiper-button-prev {
        width: 66px;
        height: 66px;
        margin-top: -33px;
      }
      .swiper-pagination {
        bottom: -50px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1340px) {
  //제품정보
  .product-info {
    .section01 {
      // .swiper-button-next, .swiper-button-prev {
      //     width: 66px;
      //     height: 66px;
      //     margin-top: -33px;
      // }
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 10px;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 10px;
      }
    }
  }
}

// 태블릿
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .brand .swiper-pagination {bottom: 40px;}
  //제품정보
  .product-info {
    .section01 {
      .swiper-button-next,
      .swiper-button-prev {
        // width: 66px;
        // height: 66px;
        // margin-top: -33px;
      }
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 10px;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 10px;
      }
      .product-swiper {
        .swiper-wrapper {
          .swiper-slide {
            .product-cnt {
              &.vertical-box {
                .img-box {
                  img {
                    width: 54%;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    .section02 {
      .product-detail-wrap {
        .detail-wrap {
          .swiper-list {
            .detail-swiper {
              .swiper-button-next,
              .swiper-button-prev {
                bottom: 40px;
              }
              .swiper-button-prev,
              .swiper-container-rtl .swiper-button-next {
                left: 38%;
              }
              .swiper-button-next,
              .swiper-container-rtl .swiper-button-prev {
                right: 38%;
              }
            }
          }
        }
      }
    }
    .section03 {
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: 10px;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 10px;
      }
    }
  }

  //팝업
  #container {
    .pull-popup {
      .popup-inner {
        width: 100%;
      }
      .popup-close {
        right: 40px;
      }
    }
  }
}


//익스
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .product-info{
    .section01{
      .product-swiper {
        .swiper-wrapper {
          .swiper-slide {
            .product-cnt {
              width:100%;
              &.vertical-box {
                width: auto;
              }
              .img-box {
                // width: 75%;
                min-height: auto;
                img {
                  // width: 80%;
                }
              }
            }
            .active-box {
              left: 50%;
              transform: translateX(-50%) !important;
            }
            &:nth-child(3) {
              .product-cnt {
                .img-box {
                  img {
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.shakeThis  {
	animation: shakeMotion 0.4s ease-in forwards 1.1s;
}
@keyframes shakeMotion {
	0%{

		-o-transform:rotate(0deg);
		-ms-transform:rotate(0deg);
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
	}
	12%{
		-o-transform:rotate(2deg);
		-ms-transform:rotate(2deg);
		-moz-transform:rotate(2deg);
		-webkit-transform:rotate(2deg);
		transform:rotate(2deg);
	}
	36%{
		-o-transform:rotate(-2deg);
		-ms-transform:rotate(-2deg);
		-moz-transform:rotate(-2deg);
		-webkit-transform:rotate(-2deg);
		transform:rotate(-2deg);
	}
	62%{
		-o-transform:rotate(1deg);
		-ms-transform:rotate(1deg);
		-moz-transform:rotate(1deg);
		-webkit-transform:rotate(1deg);
		transform:rotate(1deg);
	}
	86%{
		-o-transform:rotate(-1deg);
		-ms-transform:rotate(-1deg);
		-moz-transform:rotate(-1deg);
		-webkit-transform:rotate(-1deg);
		transform:rotate(-1deg);
	}
	100%{
		-o-transform:rotate(0deg);
		-ms-transform:rotate(0deg);
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
	}
}



