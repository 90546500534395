@charset "UTF-8";
/**
	* Computes a CSS calc function that betweens a value from
	* A to B over viewport-width A to viewport-width B.
	* Requires a media query to cap the value at B.
	* vw 단위를 활용하여 미디어쿼리 분기의 중간 상태값을
	* 계산해주는 SASS 함수.
	* 간격이나 폰트사이즈에 적용하면 자연스러운
	* 반응형 사이즈 처리가 가능 함.
	* 참고사이트 http://dohoons.com/blog/1364
	*/
/**
	* Read the article: http://sassbreak.com/viewport-relative-headings-with-sass
	* px 를 vw 로 자동으로 바꿔 줌.
	* max-breakpoint 를 설정하면 미디어쿼리 브레이크 포인트가 됨.
	* 그래서 미디어쿼리 브레이크 포인트에 맞춰 px 가 vw 로 변환됨.
	* 100% 보다 더 크게 적용 하고 싶으면 return 부분을 수정하면됨.
	* 참고사이트 http://sassbreak.com/viewport-relative-headings-with-sass/
	*/
@media (max-width: 1023px) {
  /*! contents - mobile */
  [class*="web-"] {
    display: none !important; }
  /*! 브랜드 공통 */
  .brand {
    overflow: hidden; }
    .brand .sec {
      padding: 20vw 0;
      overflow: hidden; }
      .brand .sec.section01 {
        padding-top: 13.33333vw; }
    .brand .ref-txt {
      position: relative;
      font-style: normal;
      line-height: 1.5;
      top: -1.73333vw;
      left: -0.26667vw;
      font-size: 2.4vw; }
      .brand .ref-txt.big {
        top: -5.33333vw;
        left: -0.26667vw;
        font-size: 3.46667vw; }
    .brand .title-wrap {
      text-align: center; }
      .brand .title-wrap .title {
        margin-bottom: 8vw; }
        .brand .title-wrap .title span {
          display: block;
          letter-spacing: -0.26667vw;
          font-size: 8.26667vw;
          line-height: 1.3; }
          .brand .title-wrap .title span:lang(ko) {
            font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand .title-wrap .title span:lang(en) {
            font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand .title-wrap .title span:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 300; }
          .brand .title-wrap .title span:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 300; }
          .brand .title-wrap .title span em {
            letter-spacing: -0.53333vw;
            font-family: "NotoSans-Bold";
            font-size: 8.26667vw;
            line-height: 1.3;
            color: #238754; }
            .brand .title-wrap .title span em:lang(ko) {
              font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .brand .title-wrap .title span em:lang(en) {
              font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .brand .title-wrap .title span em:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 900; }
            .brand .title-wrap .title span em:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 900; }
      .brand .title-wrap .tag-wrap {
        margin: 2.4vw 0 4vw; }
        .brand .title-wrap .tag-wrap .tag {
          display: inline-block;
          padding: 2.13333vw 3.73333vw;
          margin-bottom: 2.66667vw;
          border-radius: 4.66667vw;
          background: #fff;
          font-size: 3.73333vw;
          color: #777; }
          .brand .title-wrap .tag-wrap .tag + .tag {
            margin-left: 2vw; }
      .brand .title-wrap .tit {
        width: 56vw;
        margin: 4vw auto 6.66667vw; }
        .brand .title-wrap .tit img {
          width: 100%; }
      .brand .title-wrap .sub-txt {
        max-width: 800px;
        margin: auto; }
        .brand .title-wrap .sub-txt span {
          display: block;
          word-break: keep-all;
          font-size: 3.73333vw;
          line-height: 1.5; }
    .brand .sub-tit {
      text-overflow: ellipsis;
      white-space: normal;
      word-wrap: break-word;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 5.06667vw;
      line-height: 1.3; }
      .brand .sub-tit:lang(ko) {
        font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .brand .sub-tit:lang(en) {
        font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .brand .sub-tit:lang(ja) {
        font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 300; }
      .brand .sub-tit:lang(zh) {
        font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 300; }
    .brand .mo-title {
      display: block;
      margin-bottom: 4vw;
      font-size: 3.73333vw;
      color: #000; }
      .brand .mo-title:lang(ko) {
        font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .brand .mo-title:lang(en) {
        font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .brand .mo-title:lang(ja) {
        font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 700; }
      .brand .mo-title:lang(zh) {
        font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 700; }
    .brand .swiper-pagination {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
      transition: 0.3s opacity;
      transform: translate3d(0, 0, 0); }
      .brand .swiper-pagination .swiper-pagination-bullet {
        display: inline-block;
        width: 2.13333vw;
        height: 2.13333vw;
        margin: 0 1.33333vw;
        border-radius: 100%;
        background-color: #cccccc;
        opacity: 1; }
      .brand .swiper-pagination .swiper-pagination-bullet-active {
        background-color: #238754; }
    .brand #popup, .brand .popup {
      display: none;
      z-index: 100;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0; }
      .brand #popup.youtube .popup-wrap, .brand .popup.youtube .popup-wrap {
        width: 90%;
        height: 73.33333vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .brand #popup.img-pop .popup-wrap, .brand .popup.img-pop .popup-wrap {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 90%;
        max-height: 60%;
        height: auto; }
        .brand #popup.img-pop .popup-wrap img, .brand .popup.img-pop .popup-wrap img {
          width: 100%; }
      .brand #popup .popup-close, .brand .popup .popup-close {
        position: absolute;
        top: -9.6vw;
        right: 2.66667vw; }
        .brand #popup .popup-close .ico-close, .brand .popup .popup-close .ico-close {
          display: inline-block;
          width: 5.6vw;
          height: 5.6vw;
          background: url(../../images/common/ico_close_white.png) no-repeat center;
          background-size: contain; }
      .brand #popup.pull-popup, .brand .popup.pull-popup {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        transform: none;
        background: #f1f5f8;
        overflow-y: scroll; }
        .brand #popup.pull-popup .popup-inner, .brand .popup.pull-popup .popup-inner {
          position: relative;
          padding: 0 5.33333vw; }
        .brand #popup.pull-popup .popup-header, .brand .popup.pull-popup .popup-header {
          margin-top: 16vw; }
          .brand #popup.pull-popup .popup-header .since-txt, .brand .popup.pull-popup .popup-header .since-txt {
            font-family: "NotoSans-Light";
            font-size: 4vw;
            line-height: 1.4;
            color: #666; }
          .brand #popup.pull-popup .popup-header .tit, .brand .popup.pull-popup .popup-header .tit {
            font-family: "NotoSans-Light";
            font-size: 6.66667vw;
            line-height: 1.4;
            color: #222; }
            .brand #popup.pull-popup .popup-header .tit em:lang(ko), .brand .popup.pull-popup .popup-header .tit em:lang(ko) {
              font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .brand #popup.pull-popup .popup-header .tit em:lang(en), .brand .popup.pull-popup .popup-header .tit em:lang(en) {
              font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .brand #popup.pull-popup .popup-header .tit em:lang(ja), .brand .popup.pull-popup .popup-header .tit em:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 900; }
            .brand #popup.pull-popup .popup-header .tit em:lang(zh), .brand .popup.pull-popup .popup-header .tit em:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 900; }
        .brand #popup.pull-popup .popup-content .column .txt-box, .brand .popup.pull-popup .popup-content .column .txt-box {
          margin-top: 6.66667vw;
          padding-right: 4vw;
          overflow-y: auto;
          word-break: keep-all;
          font-family: "NotoSans-Light";
          font-size: 3.73333vw;
          line-height: 1.5;
          color: #222; }
        .brand #popup.pull-popup .popup-content .column .img-box, .brand .popup.pull-popup .popup-content .column .img-box {
          text-align: center; }
          .brand #popup.pull-popup .popup-content .column .img-box img, .brand .popup.pull-popup .popup-content .column .img-box img {
            width: 80%; }
        .brand #popup.pull-popup .popup-content .column .tag-area, .brand .popup.pull-popup .popup-content .column .tag-area {
          margin-top: 5.33333vw; }
          .brand #popup.pull-popup .popup-content .column .tag-area .tag, .brand .popup.pull-popup .popup-content .column .tag-area .tag {
            display: inline-block;
            margin-right: 1.33333vw;
            padding: 2.13333vw 3.73333vw;
            margin-bottom: 2.66667vw;
            border-radius: 4.66667vw;
            background: #fff;
            font-size: 3.73333vw; }
        .brand #popup.pull-popup .popup-content .column .gallery-view-area, .brand .popup.pull-popup .popup-content .column .gallery-view-area {
          margin-top: 4vw; }
        .brand #popup.pull-popup .popup-content .column .gallery-view-img .img-item img, .brand .popup.pull-popup .popup-content .column .gallery-view-img .img-item img {
          width: 100%; }
        .brand #popup.pull-popup .popup-content .column .gallery-view-img .img-item .tit, .brand .popup.pull-popup .popup-content .column .gallery-view-img .img-item .tit {
          text-align: center; }
        .brand #popup.pull-popup .popup-content .column .gallery-btn, .brand .popup.pull-popup .popup-content .column .gallery-btn {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 2.66667vw;
          font-size: 0; }
          .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item {
            position: relative;
            display: inline-block;
            width: 20.53333vw;
            margin-left: 2.26667vw;
            margin-bottom: 2.26667vw; }
            .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item:nth-child(4n+1), .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item:nth-child(4n+1) {
              margin-left: 0vw; }
            .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item.on button::after, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item.on button::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0.3; }
          .brand #popup.pull-popup .popup-content .column .gallery-btn button, .brand .popup.pull-popup .popup-content .column .gallery-btn button {
            width: 100%;
            padding: 0; }
          .brand #popup.pull-popup .popup-content .column .gallery-btn img, .brand .popup.pull-popup .popup-content .column .gallery-btn img {
            width: 100%; }
        .brand #popup.pull-popup .popup-content.type02 .column .txt-box, .brand .popup.pull-popup .popup-content.type02 .column .txt-box {
          height: auto; }
        .brand #popup.pull-popup .popup-content.type02 .column .img-box, .brand .popup.pull-popup .popup-content.type02 .column .img-box {
          text-align: center; }
          .brand #popup.pull-popup .popup-content.type02 .column .img-box img, .brand .popup.pull-popup .popup-content.type02 .column .img-box img {
            width: 70%; }
        .brand #popup.pull-popup .popup-close .ico-close, .brand .popup.pull-popup .popup-close .ico-close {
          background-image: url(../../images/common/ico_close_black.png); }
    .brand .ft-txt {
      padding: 4vw 0;
      color: #fff;
      border-bottom: 1px solid rgba(204, 204, 204, 0.1);
      background-color: #666666; }
      .brand .ft-txt .noti-area .mgt, .brand .ft-txt .noti-area .mgt-mo {
        margin-top: 1.6vw; }
      .brand .ft-txt .noti-area span {
        display: block;
        font-size: 3.2vw;
        line-height: 1.5; }
        .brand .ft-txt .noti-area span.point-txt {
          padding-left: 1.6vw;
          text-indent: -2.13333vw; }
      .brand .ft-txt .noti-area .sub-txt {
        color: #aaa; }
      .brand .ft-txt .noti-area .desc {
        margin-top: 2.66667vw; }
    .brand .reference {
      padding: 4vw 0;
      background-color: #d8dcdf; }
      .brand .reference p {
        text-align: left;
        font-size: 3.2vw;
        line-height: 1.5; }
      .brand .reference span {
        display: block;
        text-align: left;
        font-size: 3.2vw;
        line-height: 1.2;
        color: #666; }
        .brand .reference span a {
          font-size: 3.2vw;
          line-height: 1.5;
          color: #666; }
  /*! 브랜드스토리 공통 */
  .story .sec {
    padding: 20vw 0;
    text-align: center; }
    .story .sec.reference {
      padding: 4vw 0; }
  .section05 {
    padding-bottom: 20.93333vw;
    background-color: #f1f5f8; }
    .section05 .title-wrap {
      margin-bottom: 9.33333vw; }
      .section05 .title-wrap .sub-txt {
        margin-top: 5.33333vw; }
    .section05 .media-cnt .thum-img {
      position: relative;
      width: 100%;
      margin: auto;
      cursor: pointer; }
      .section05 .media-cnt .thum-img .btn-play {
        width: 12vw;
        height: 12vw;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -6vw;
        margin-left: -6vw; }
      .section05 .media-cnt .thum-img img {
        width: 100%; }
      .section05 .media-cnt .thum-img .txt {
        position: absolute;
        left: 2.66667vw;
        bottom: 2.66667vw;
        font-family: "NotoSans-Medium";
        font-size: 2.66667vw;
        line-height: 1.3;
        color: #fff;
        z-index: 1; }
    .section05 .media-cnt .pop-cnt {
      display: none; }
    .section05 .media-cnt .media-swiper {
      position: relative;
      margin: auto;
      width: 100%;
      min-height: 52.53333vw; }
    .section05 .media-cnt .swiper-pagination {
      bottom: -8vw; }
    .section05 .media-cnt .swiper-button-prev,
    .section05 .media-cnt .swiper-button-next {
      display: none; }
  .section06 {
    background-color: #666;
    padding: 5.33333vw 0;
    border-bottom: 1px solid #707070; }
    .section06 .noti-area span {
      display: block;
      font-family: "NotoSans-Light";
      font-size: 3.73333vw;
      line-height: 1.5;
      color: #fff; }
    .section06 .noti-area .point-txt {
      text-indent: -1.73333vw;
      padding-left: 1.6vw; }
  .product-info .title-wrap .sub-tit {
    display: block;
    margin-bottom: 2.08333vw;
    font-size: 4vw;
    color: #666; }
    .product-info .title-wrap .sub-tit:lang(ko) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .product-info .title-wrap .sub-tit:lang(en) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .product-info .title-wrap .sub-tit:lang(ja) {
      font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
    .product-info .title-wrap .sub-tit:lang(zh) {
      font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
  .product-info .section01 {
    padding-top: 13.33333vw;
    text-align: center;
    background: linear-gradient(#fff 56%, #f1f5f8 44%); }
    .product-info .section01 .product-swiper {
      padding-bottom: 20vw; }
      .product-info .section01 .product-swiper .swiper-wrapper {
        position: relative;
        display: flex; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide {
          flex-shrink: 0;
          max-width: 554px;
          width: 73.86667vw !important;
          height: 73.86667vw;
          border-radius: 100%;
          justify-content: center;
          opacity: 0; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt {
            width: 90%;
            margin-top: 1.33333vw;
            align-self: center;
            position: relative;
            z-index: 1; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt.vertical-box .img-box img {
              width: 40%;
              margin: 0 auto; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box {
              display: flex;
              margin: auto;
              justify-content: center; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box img {
                align-self: center;
                width: 80%; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt {
              display: block;
              margin-top: 5.33333vw;
              font-size: 4.8vw;
              color: #fff; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(ko) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(en) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(ja) {
                font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(zh) {
                font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt.mgt0 {
                margin-top: -4.66667vw; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt em {
                display: block;
                margin-top: 0.53333vw;
                font-size: 3.73333vw; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .active-box {
            opacity: 0;
            position: absolute;
            transform: translateX(0) !important;
            width: 73.86667vw;
            height: 73.86667vw;
            margin: auto;
            border-radius: 100%; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on {
            position: relative;
            display: flex;
            opacity: 1;
            margin: auto; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .active-box {
              opacity: 1;
              background-color: #238754;
              box-shadow: 15px 15px 15px 0 rgba(0, 0, 0, 0.1); }
      .product-info .section01 .product-swiper.vertical-type .swiper-wrapper .swiper-slide.on .product-cnt {
        width: initial;
        margin-top: 0; }
        .product-info .section01 .product-swiper.vertical-type .swiper-wrapper .swiper-slide.on .product-cnt .txt {
          margin-top: 0; }
        .product-info .section01 .product-swiper.vertical-type .swiper-wrapper .swiper-slide.on .product-cnt .img-box {
          width: 100%; }
    .product-info .section01 .swiper-button-next,
    .product-info .section01 .swiper-button-prev {
      display: block;
      width: 4.66667vw;
      height: 8.8vw;
      background: url(../../images/mo/brand/mo_product_arr.png) no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      margin-top: -2.66667vw;
      cursor: pointer; }
    .product-info .section01 .swiper-button-prev {
      left: 4vw; }
    .product-info .section01 .swiper-button-next {
      right: 4vw;
      transform: rotate(180deg); }
    .product-info .section01 .swiper-pagination {
      left: 0;
      right: 0;
      bottom: 10.66667vw; }
  .product-info .section02 .product-cnt {
    display: none; }
    .product-info .section02 .product-cnt.on {
      display: block; }
  .product-info .section02 .product-detail-wrap {
    background-color: #fff; }
    .product-info .section02 .product-detail-wrap .title {
      display: flex;
      padding: 9.73333vw 0 1.5625vw;
      border-bottom: 1px solid #222;
      box-sizing: border-box; }
      .product-info .section02 .product-detail-wrap .title .tit-box {
        align-self: center; }
        .product-info .section02 .product-detail-wrap .title .tit-box .ico {
          display: inline-block;
          width: 4.8vw;
          height: 4.8vw;
          margin-right: 1.33333vw;
          background: url(../../images/mo/ico_detail.png);
          background-size: cover;
          vertical-align: middle; }
        .product-info .section02 .product-detail-wrap .title .tit-box .txt {
          display: inline-block;
          font-size: 4.53333vw;
          vertical-align: middle; }
          .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
      .product-info .section02 .product-detail-wrap .title a {
        display: block;
        margin-left: auto;
        min-height: 10.13333vw;
        padding: 2.93333vw 5.33333vw;
        background-color: #238754;
        border-radius: 5.33333vw;
        box-sizing: border-box; }
        .product-info .section02 .product-detail-wrap .title a .ico {
          display: inline-block;
          width: 3.06667vw;
          height: 3.46667vw;
          margin-right: 1.6vw;
          vertical-align: top;
          background: url(../../images/mo/icon_down.png);
          background-size: contain; }
        .product-info .section02 .product-detail-wrap .title a .txt {
          display: inline-block;
          vertical-align: top;
          font-size: 3.73333vw;
          color: #fff; }
    .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-list-wrap .detail-list:last-child {
      border-bottom: 0; }
    .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper {
      padding-bottom: 16vw;
      position: relative;
      border-bottom: 1px dotted #ddd;
      overflow: hidden; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-wrapper {
        display: flex; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-slide {
        width: 100%;
        flex-shrink: 0; }
        .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-slide img {
          width: 100%; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination {
        margin-top: 4vw;
        text-align: center; }
        .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination span {
          font-family: "Barlow";
          font-weight: 500;
          font-size: 4vw;
          color: #ccc; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination span.swiper-pagination-current {
            color: #222; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev {
        width: 1.46667vw;
        height: 3.33333vw;
        position: absolute;
        bottom: 8vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev,
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-container-rtl .swiper-button-next {
        left: 40%;
        right: auto;
        background-image: url(../../images/common/ico_prev_type04.png); }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-container-rtl .swiper-button-prev {
        right: 40%;
        left: auto;
        background-image: url(../../images/common/ico_next_type04.png); }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list {
      padding: 6.66667vw 0;
      border-bottom: 0.4vw dotted #ddd; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top {
        display: flex; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico {
          display: inline-block;
          width: 14%;
          height: 11.6vw;
          margin-right: 4vw;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-position: center; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico01 {
            background-image: url(../../images/brand/ico_detail01.png);
            background-size: 12vw 9.6vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico02 {
            background-image: url(../../images/brand/ico_detail02.png);
            background-size: 8.26667vw 10.93333vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico03 {
            background-image: url(../../images/brand/ico_detail03.png);
            background-size: contain; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico04 {
            background-image: url(../../images/brand/ico_detail04.png);
            background-size: 7.73333vw 11.2vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico05 {
            background-image: url(../../images/brand/ico_detail05.png);
            background-size: 5.6vw 11.6vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico06 {
            background-image: url(../../images/brand/ico_detail06.png);
            background-size: 9.46667vw 8.53333vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico07 {
            background-image: url(../../images/brand/ico_detail07.png);
            background-size: 9.46667vw 9.46667vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico08 {
            background-image: url(../../images/brand/ico_detail08.png);
            background-size: 10vw 10vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico09 {
            background-image: url(../../images/brand/ico_detail09.png);
            background-size: 6vw 8.66667vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico10 {
            background-image: url(../../images/brand/ico_detail10.png);
            background-size: 8vw 9.06667vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico11 {
            background-image: url(../../images/brand/ico_detail11.png);
            background-size: 7.33333vw 9.33333vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico12 {
            background-image: url(../../images/brand/ico_detail12.png);
            background-size: 9.33333vw 9.6vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico13 {
            background-image: url(../../images/brand/ico_detail13.png);
            background-size: 6.26667vw 5.6vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico14 {
            background-image: url(../../images/brand/ico_detail14.png);
            background-size: 9.46667vw 10.4vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico15 {
            background-image: url(../../images/brand/ico_detail15.png);
            background-size: 8.8vw 8.8vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico16 {
            background-image: url(../../images/brand/ico_detail16.png);
            background-size: 8vw 10vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico17 {
            background-image: url(../../images/brand/ico_detail17.png);
            background-size: 8.93333vw 8.66667vw; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box {
          width: 86%;
          align-self: center; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit {
            display: block;
            margin-bottom: 1.33333vw;
            font-size: 4vw;
            line-height: 1.6; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(ko) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(en) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt {
            line-height: 1.5;
            font-size: 3.73333vw;
            color: #666; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt .sub-tit {
              display: block;
              font-family: "NotoSans-DemiLight";
              font-size: 3.73333vw; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt .sub-txt {
              display: block;
              font-size: 3.46667vw;
              color: #666; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .sub {
            display: block;
            margin-top: 4vw; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .sub .sub-tit {
              font-family: "NotoSans-DemiLight";
              font-size: 4vw;
              color: #222; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .sub .sub-txt {
              display: block;
              font-size: 3.46667vw;
              color: #666;
              line-height: 1.6; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .sub .sub-txt:not(:last-child) {
                margin-top: 1.33333vw; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box {
        margin-top: 5.33333vw; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box .product {
          margin-bottom: 5.33333vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box .product img {
            display: block;
            margin-bottom: 3.33333vw; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box .product span {
            display: block; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .img-txt {
        display: block;
        margin-top: 4vw; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .img-txt img {
          width: 100%; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box {
        display: block;
        margin-top: 4vw; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box span {
          display: block;
          word-break: keep-all;
          font-size: 3.73333vw;
          line-height: 1.6;
          color: #666; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc-t {
          color: #222;
          margin-bottom: 2.4vw; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc {
          position: relative;
          padding-left: 2.13333vw;
          line-height: 1.7;
          letter-spacing: -1px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc::before {
            content: "";
            position: absolute;
            top: 2.66667vw;
            left: 0;
            width: 0.93333vw;
            height: 0.93333vw;
            background: #666;
            border-radius: 100%; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box.mt-18 {
          margin-top: 2.4vw; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box-type02 .desc {
        word-break: keep-all;
        font-size: 3.73333vw;
        line-height: 1.6;
        color: #666;
        padding-left: 4.26667vw;
        text-indent: -4.4vw; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap {
        width: 100%;
        margin-top: 4.8vw;
        background-color: #f1f5f8; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt {
          flex: auto;
          padding: 6.26667vw 4vw 0;
          box-sizing: border-box; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span {
            display: block; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.content {
              word-break: keep-all;
              font-size: 3.73333vw;
              line-height: 1.6;
              color: #666; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name {
              margin-bottom: 1.33333vw; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico {
                display: inline-block;
                width: 4.8vw;
                height: 4.8vw;
                background-repeat: no-repeat;
                background-position: center top;
                vertical-align: middle; }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico01 {
                  margin-right: 2.13333vw;
                  background-size: 4.8vw;
                  height: 4.8vw;
                  background-image: url(../../images/brand/ico_detail03_01.png); }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico02 {
                  background-size: 3.2vw;
                  height: 5.46667vw;
                  background-image: url(../../images/brand/ico_detail03_02.png); }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico03 {
                  background-size: 3.73333vw;
                  height: 5.33333vw;
                  background-image: url(../../images/brand/ico_detail03_03.png); }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico04 {
                  margin-right: 2vw;
                  background-size: 5.2vw;
                  height: 5.2vw;
                  width: 5.2vw;
                  background-image: url(../../images/brand/ico_detail03_04.png); }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico05 {
                  margin-right: 2vw;
                  background-size: 5.2vw;
                  height: 5.2vw;
                  width: 5.2vw;
                  background-image: url(../../images/brand/ico_detail03_05.png); }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .txt {
                display: inline-block;
                vertical-align: middle;
                font-size: 3.73333vw;
                line-height: 1.6;
                color: #666; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt:last-child {
            padding-bottom: 6.26667vw; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type02 .cnt {
          float: none;
          width: 100%; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type02 .cnt + .cnt {
            padding-top: 0; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type03 .cnt {
          float: none;
          width: 100%; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .list {
        display: block;
        padding-left: 1.33333vw;
        text-indent: -2vw;
        line-height: 1.8;
        font-size: 3.46667vw;
        color: #666; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .list:nth-child(2) {
          margin-top: 2vw; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .sub-tit {
        display: block;
        margin-top: 6.66667vw;
        font-size: 3.73333vw;
        line-height: 1.5; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .sub-txt {
        display: block;
        font-size: 3.46667vw;
        line-height: 1.5;
        color: #666;
        word-break: keep-all; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list:last-child {
        border-bottom: 0.26667vw solid #ccc; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list.bdb0 {
        border-bottom: 0; }
    .product-info .section02 .product-detail-wrap .detail-table-wrap {
      position: relative;
      width: 100%;
      overflow-x: scroll; }
      .product-info .section02 .product-detail-wrap .detail-table-wrap.on:after {
        opacity: 1; }
      .product-info .section02 .product-detail-wrap .detail-table-wrap:after {
        content: "";
        display: block;
        clear: both;
        width: 22.66667vw;
        height: 22.66667vw;
        margin-top: -11.33333vw;
        background: url(../../images/brand/ico_swiper.png) no-repeat;
        background-size: contain;
        position: absolute;
        left: 33.46667vw;
        top: 50%;
        opacity: 0;
        transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all; }
    .product-info .section02 .product-detail-wrap table {
      position: relative; }
      .product-info .section02 .product-detail-wrap table.table-type01 {
        width: 100%;
        margin-top: 4vw; }
        .product-info .section02 .product-detail-wrap table.table-type01 thead {
          background: #f1f5f8; }
        .product-info .section02 .product-detail-wrap table.table-type01 th, .product-info .section02 .product-detail-wrap table.table-type01 td {
          min-width: 30.66667vw;
          padding: 3.33333vw 4.66667vw;
          text-align: center;
          color: #666;
          font-family: "NotoSans-DemiLight";
          font-size: 3.46667vw;
          word-break: break-all;
          white-space: nowrap;
          border: 1px solid #ddd;
          box-sizing: border-box; }
          .product-info .section02 .product-detail-wrap table.table-type01 th:first-child, .product-info .section02 .product-detail-wrap table.table-type01 td:first-child {
            border-left: none; }
          .product-info .section02 .product-detail-wrap table.table-type01 th:last-child, .product-info .section02 .product-detail-wrap table.table-type01 td:last-child {
            border-right: none; }
        .product-info .section02 .product-detail-wrap table.table-type01 th {
          color: #222;
          border-top: 1px solid #888; }
          .product-info .section02 .product-detail-wrap table.table-type01 th:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .product-detail-wrap table.table-type01 th:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .product-detail-wrap table.table-type01 th:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .product-info .section02 .product-detail-wrap table.table-type01 th:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
  .product-info .section02 .precautions {
    padding: 7.46667vw 0 13.33333vw;
    text-align: center; }
    .product-info .section02 .precautions .txt {
      font-size: 3.73333vw;
      line-height: 1.5;
      color: #222; }
    .product-info .section02 .precautions .btn-download {
      display: block;
      width: 40vw;
      margin: 2.66667vw auto 0;
      padding: 2.66667vw 0;
      background-color: #666;
      border-radius: 8vw; }
      .product-info .section02 .precautions .btn-download .txt {
        display: inline-block;
        font-size: 4vw;
        vertical-align: middle;
        color: #fff; }
        .product-info .section02 .precautions .btn-download .txt:lang(ko) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .product-info .section02 .precautions .btn-download .txt:lang(en) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .product-info .section02 .precautions .btn-download .txt:lang(ja) {
          font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
        .product-info .section02 .precautions .btn-download .txt:lang(zh) {
          font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
      .product-info .section02 .precautions .btn-download .ico {
        display: inline-block;
        width: 3.06667vw;
        height: 3.46667vw;
        margin-right: 1.33333vw;
        background: url(../../images/mo/icon_down.png) no-repeat;
        background-size: cover;
        vertical-align: middle; }
  .product-info .section03 {
    padding: 13.33333vw 0 24vw;
    background-color: #333; }
    .product-info .section03 .title-wrap {
      text-align: center; }
      .product-info .section03 .title-wrap .related-tit {
        display: block;
        margin-bottom: 5.33333vw;
        font-size: 5.06667vw;
        color: #fff; }
        .product-info .section03 .title-wrap .related-tit:lang(ko) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .product-info .section03 .title-wrap .related-tit:lang(en) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .product-info .section03 .title-wrap .related-tit:lang(ja) {
          font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
        .product-info .section03 .title-wrap .related-tit:lang(zh) {
          font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
    .product-info .section03 .related-swiper {
      overflow: hidden; }
      .product-info .section03 .related-swiper .swiper-wrapper {
        display: flex; }
        .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide {
          flex-shrink: 0;
          width: 76vw; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide a {
            display: block; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .img-box img {
            width: 100%; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box {
            margin-top: 5.33333vw; }
            .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span {
              display: block;
              text-align: center; }
              .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit {
                margin-bottom: 2vw;
                font-family: "NotoSans-Medium";
                font-size: 4vw;
                color: #fff; }
              .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.txt {
                font-size: 3.73333vw;
                color: #999; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide:nth-child(1) {
            display: block;
            margin-left: 0; }
    .product-info .section03 .swiper-button-next,
    .product-info .section03 .swiper-button-prev {
      display: none; }
    .product-info .section03 .swiper-pagination {
      bottom: -13.33333vw;
      left: 0;
      right: 0; } }

@media screen and (max-width: 1023px) and (min-width: 751px) and (max-width: 1023px) {
  /*! 브랜드 공통 */
  .brand .sec {
    padding: 150px 0; }
    .brand .sec.section01 {
      padding-top: 100px; }
  .brand .ref-txt {
    top: -13px;
    left: -2px;
    font-size: 18px; }
    .brand .ref-txt.big {
      top: -40px;
      left: -2px;
      font-size: 26px; }
  .brand .title-wrap .title {
    margin-bottom: 60px; }
    .brand .title-wrap .title span {
      font-size: 62px; }
      .brand .title-wrap .title span em {
        font-size: 62px; }
  .brand .title-wrap .tag-wrap {
    margin: 18px 0 30px; }
    .brand .title-wrap .tag-wrap .tag {
      display: inline-block;
      padding: 16px 28px;
      margin-bottom: 20px;
      border-radius: 35px;
      background: #fff;
      font-size: 28px; }
      .brand .title-wrap .tag-wrap .tag + .tag {
        margin-left: 15px; }
  .brand .title-wrap .sub-tit {
    font-size: 38px; }
  .brand .title-wrap .tit {
    width: 420px;
    margin: 30px auto 50px; }
  .brand .title-wrap .sub-txt {
    max-width: 800px; }
    .brand .title-wrap .sub-txt span {
      font-size: 28px; }
  .brand .mo-title {
    font-size: 28px;
    margin-bottom: 30px; }
  .brand .swiper-pagination .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    margin: 0 10px; }
  .brand #popup.youtube .pop-wrap, .brand .popup.youtube .pop-wrap {
    height: 550px; }
  .brand #popup .popup-close, .brand .popup .popup-close {
    top: -72px;
    right: 20px; }
    .brand #popup .popup-close .ico-close, .brand .popup .popup-close .ico-close {
      width: 42px;
      height: 42px; }
  .brand #popup.pull-popup .popup-inner, .brand .popup.pull-popup .popup-inner {
    padding: 0 40px; }
  .brand #popup.pull-popup .popup-header, .brand .popup.pull-popup .popup-header {
    margin-top: 120px; }
    .brand #popup.pull-popup .popup-header .since-txt, .brand .popup.pull-popup .popup-header .since-txt {
      font-size: 30px; }
    .brand #popup.pull-popup .popup-header .tit, .brand .popup.pull-popup .popup-header .tit {
      font-size: 50px; }
  .brand #popup.pull-popup .popup-content .column .txt-box, .brand .popup.pull-popup .popup-content .column .txt-box {
    margin-top: 50px;
    padding-right: 30px;
    font-size: 28px; }
  .brand #popup.pull-popup .popup-content .column .tag-area, .brand .popup.pull-popup .popup-content .column .tag-area {
    margin-top: 40px; }
    .brand #popup.pull-popup .popup-content .column .tag-area .tag, .brand .popup.pull-popup .popup-content .column .tag-area .tag {
      margin-right: 10px;
      padding: 16px 28px;
      margin-bottom: 20px;
      border-radius: 35px;
      font-size: 28px; }
  .brand #popup.pull-popup .popup-content .column .gallery-view-area, .brand .popup.pull-popup .popup-content .column .gallery-view-area {
    margin-top: 30px; }
  .brand #popup.pull-popup .popup-content .column .gallery-view-img .img-item, .brand .popup.pull-popup .popup-content .column .gallery-view-img .img-item {
    padding: 30px 22px 60px; }
  .brand #popup.pull-popup .popup-content .column .gallery-btn, .brand .popup.pull-popup .popup-content .column .gallery-btn {
    margin-top: 20px; }
    .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item {
      width: 154px;
      margin-left: 17px; }
      .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item:nth-child(4n+1), .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item:nth-child(4n+1) {
        margin-left: 0px; }
  .brand .ft-txt {
    padding: 30px 0; }
    .brand .ft-txt .noti-area span {
      font-size: 24px; }
      .brand .ft-txt .noti-area span.point-txt {
        padding-left: 12px;
        text-indent: -16px; }
    .brand .ft-txt .noti-area .desc {
      margin-top: 20px; }
  .brand .reference {
    padding: 30px 0; }
    .brand .reference p {
      font-size: 24px; }
    .brand .reference span {
      font-size: 24px; }
      .brand .reference span a {
        font-size: 24px; }
  .product-info .title-wrap .sub-tit {
    margin-bottom: 40px;
    font-size: 30px; }
  .product-info .section01 {
    padding-top: 100px; }
    .product-info .section01 .product-swiper {
      padding-bottom: 150px; }
      .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide {
        width: 554px;
        height: 554px; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt {
          margin-top: 10px; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt.vertical-box .img-box img {
            margin: 0 auto; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt {
            margin-top: 40px;
            font-size: 36px; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt.mgt0 {
              margin-top: -35px; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt em {
              margin-top: 4px;
              font-size: 28px; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box {
            margin: auto; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .shadow-box {
          left: 50%;
          width: 554px;
          height: 554px;
          margin-left: -277px; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .active-box {
          width: 554px;
          height: 554px; }
      .product-info .section01 .product-swiper .swiper-pagination {
        bottom: 110px; }
    .product-info .section01 .swiper-pagination {
      bottom: 80px; }
  .product-info .section02 .product-detail-wrap .title {
    padding: 73px 0 10px; }
    .product-info .section02 .product-detail-wrap .title .tit-box .ico {
      width: 36px;
      height: 36px;
      margin-right: 10px; }
    .product-info .section02 .product-detail-wrap .title .tit-box .txt {
      font-size: 34px; }
    .product-info .section02 .product-detail-wrap .title a {
      padding: 22px 40px;
      border-radius: 40px;
      min-height: 76px; }
      .product-info .section02 .product-detail-wrap .title a .ico {
        width: 23px;
        height: 26px;
        margin-right: 12px; }
      .product-info .section02 .product-detail-wrap .title a .txt {
        font-size: 28px; }
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper {
    padding-bottom: 120px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination {
      margin-top: 30px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination span {
        font-size: 30px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
    .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev {
      width: 11px;
      height: 25px;
      bottom: 60px; }
  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list {
    padding: 50px 0; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico {
      width: 97px;
      height: 87px;
      margin-right: 30px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico01 {
        background-size: 90px 72px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico02 {
        background-size: 62px 82px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico03 {
        background-size: 79px 84px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico04 {
        background-size: 58px 84px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico05 {
        background-size: 42px 87px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico06 {
        background-size: 71px 64px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico07 {
        background-size: 71px 71px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico08 {
        background-size: 75px 75px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico10 {
        background-size: 60px 68px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico11 {
        background-size: 55px 70px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico12 {
        background-size: 70px 72px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico14 {
        background-size: 71px 78px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico15 {
        background-size: 66px 66px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico16 {
        background-size: 60px 75px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box {
      width: calc(100% - 90px); }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit {
        margin-bottom: 10px;
        font-size: 30px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt {
        font-size: 28px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt .sub-tit {
          font-size: 28px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt .sub-txt {
          font-size: 26px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .img-txt {
      margin-top: 30px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box {
      margin-top: 30px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box span {
        font-size: 28px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box span.desc {
          padding-left: 16px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc-t {
        margin-bottom: 18px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc {
        padding-left: 16px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc::before {
          top: 24px;
          width: 4px;
          height: 4px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box-type02 .desc {
      font-size: 28px;
      padding-left: 32px;
      text-indent: -33px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box {
      margin-top: 40px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box .product {
        margin-bottom: 40px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .product-box .product img {
          margin-bottom: 25px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap {
      margin-top: 36px; }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt {
        padding: 47px 30px 0; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.content {
          font-size: 28px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico {
          width: 36px;
          height: 36px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico01 {
            margin-right: 16px;
            background-size: 36px;
            height: 36px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico02 {
            background-size: 24px;
            height: 41px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico03 {
            background-size: 28px;
            height: 40px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico04 {
            margin-right: 15px;
            background-size: 39px;
            height: 39px;
            width: 39px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico05 {
            margin-right: 15px;
            background-size: 39px;
            height: 39px;
            width: 39px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .txt {
          font-size: 28px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt:last-child {
          padding-bottom: 47px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .sub-tit {
      margin-top: 50px;
      font-size: 28px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .sub-txt {
      font-size: 26px; }
    .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .list {
      padding-left: 10px;
      text-indent: -15px;
      font-size: 26px; }
  .product-info .section02 .product-detail-wrap table.table-type01 {
    margin-top: 30px; }
    .product-info .section02 .product-detail-wrap table.table-type01 th, .product-info .section02 .product-detail-wrap table.table-type01 td {
      min-width: 230px;
      padding: 25px 35px;
      font-size: 26px; }
  .product-info .section02 .product-detail-wrap table:after {
    width: 170px;
    height: 170px;
    margin-top: -85px;
    left: 251px; }
  .product-info .section02 .precautions {
    padding: 56px 0 100px; }
    .product-info .section02 .precautions .txt {
      font-size: 28px; }
    .product-info .section02 .precautions .btn-download {
      width: 300px;
      margin: 20px auto 0;
      padding: 20px 0;
      border-radius: 60px; }
      .product-info .section02 .precautions .btn-download .txt {
        font-size: 30px; }
      .product-info .section02 .precautions .btn-download .ico {
        width: 23px;
        height: 26px;
        margin-right: 10px; }
  .product-info .section03 {
    padding: 100px 0 180px; }
    .product-info .section03 .title-wrap .related-tit {
      margin-bottom: 40px;
      font-size: 38px; }
    .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide {
      width: 570px; }
      .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box {
        margin-top: 40px; }
        .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit {
          margin-bottom: 15px;
          font-size: 30px; }
        .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.txt {
          font-size: 28px; }
    .product-info .section03 .swiper-pagination {
      bottom: -100px; } }

@media (max-width: 1023px) {
  .ui-media-section {
    background-color: #f1f5f8;
    position: relative; } }

@media screen and (max-width: 1023px) and (-ms-high-contrast: active), (max-width: 1023px) and (-ms-high-contrast: none) {
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .active-box {
    left: 0;
    margin: auto; }
  .brand .title-wrap .title span {
    letter-spacing: -1.8px; }
  .benachio-qna .section03 .sec03-graph-wrap .cate span:nth-child(2) {
    margin-left: 2.4vw !important; } }

@media (min-width: 1024px) {
  /*! contents - pc */
  /*! 브랜드 공통 */
  .brand .sec {
    padding: 100px 0;
    overflow: hidden; }
  .brand .ref-txt {
    position: relative;
    font-style: normal;
    line-height: 1.5;
    top: -12px;
    left: -4px;
    font-size: 12px; }
    .brand .ref-txt.big {
      top: -39px;
      left: -10px;
      font-size: 16px; }
  .brand .title-wrap {
    text-align: center; }
    .brand .title-wrap .title {
      margin-bottom: 17px; }
      .brand .title-wrap .title span {
        display: block;
        font-size: 52px;
        line-height: 1.3; }
        .brand .title-wrap .title span:lang(ko) {
          font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .brand .title-wrap .title span:lang(en) {
          font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .brand .title-wrap .title span:lang(ja) {
          font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 300; }
        .brand .title-wrap .title span:lang(zh) {
          font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 300; }
        .brand .title-wrap .title span em {
          font-size: 52px;
          line-height: 1.3;
          color: #238754; }
          .brand .title-wrap .title span em:lang(ko) {
            font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand .title-wrap .title span em:lang(en) {
            font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand .title-wrap .title span em:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 900; }
          .brand .title-wrap .title span em:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 900; }
    .brand .title-wrap .sub-tit {
      display: block !important;
      max-width: 670px;
      margin: auto;
      text-overflow: ellipsis;
      white-space: normal;
      word-wrap: break-word;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 30px; }
      .brand .title-wrap .sub-tit:lang(ko) {
        font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .brand .title-wrap .sub-tit:lang(en) {
        font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .brand .title-wrap .sub-tit:lang(ja) {
        font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 700; }
      .brand .title-wrap .sub-tit:lang(zh) {
        font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 700; }
    .brand .title-wrap .tit {
      margin: 20px 0 38px; }
    .brand .title-wrap .sub-txt {
      margin: auto; }
      .brand .title-wrap .sub-txt span {
        display: block;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-size: 22px; }
  .brand .swiper-pagination {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1.5625vw;
    width: 100%;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    opacity: 1; }
    .brand .swiper-pagination .swiper-pagination-bullet {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 7px;
      border-radius: 100%;
      background-color: #cccccc;
      cursor: pointer;
      opacity: 1; }
    .brand .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #238754; }
  .brand #popup, .brand .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    transform: translate(-50%, -50%);
    z-index: 100; }
    .brand #popup .popup-wrap, .brand .popup .popup-wrap {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 33.333333%;
      height: auto;
      transform: translate(-50%, -50%); }
    .brand #popup .popup-close, .brand .popup .popup-close {
      position: absolute;
      top: -49px;
      right: 0; }
      .brand #popup .popup-close .ico-close, .brand .popup .popup-close .ico-close {
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: url(../../images/common/ico_close_white.png);
        background-position: center;
        background-repeat: no-repeat;
        background: cover; }
    .brand #popup.youtube .popup-wrap, .brand .popup.youtube .popup-wrap {
      width: 978px;
      height: 550px; }
    .brand #popup.img-pop .popup-wrap, .brand .popup.img-pop .popup-wrap {
      max-width: 980px;
      max-height: 720px;
      text-align: right; }
      .brand #popup.img-pop .popup-wrap img, .brand .popup.img-pop .popup-wrap img {
        max-width: 100%; }
    .brand #popup.pull-popup, .brand .popup.pull-popup {
      overflow-y: scroll;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      transform: none;
      z-index: 100;
      background-image: url(../../images/common/popup_bg01.png);
      background-size: 100% 100%; }
      .brand #popup.pull-popup .popup-inner, .brand .popup.pull-popup .popup-inner {
        position: relative;
        width: 1200px;
        margin: 0 auto 120px; }
      .brand #popup.pull-popup .popup-header, .brand .popup.pull-popup .popup-header {
        margin-top: 120px;
        text-align: center; }
        .brand #popup.pull-popup .popup-header .since-txt, .brand .popup.pull-popup .popup-header .since-txt {
          font-size: 22px;
          color: #666; }
          .brand #popup.pull-popup .popup-header .since-txt:lang(ko), .brand .popup.pull-popup .popup-header .since-txt:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand #popup.pull-popup .popup-header .since-txt:lang(en), .brand .popup.pull-popup .popup-header .since-txt:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand #popup.pull-popup .popup-header .since-txt:lang(ja), .brand .popup.pull-popup .popup-header .since-txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .brand #popup.pull-popup .popup-header .since-txt:lang(zh), .brand .popup.pull-popup .popup-header .since-txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
        .brand #popup.pull-popup .popup-header .tit, .brand .popup.pull-popup .popup-header .tit {
          margin-top: 15px;
          font-size: 52px;
          color: #222; }
          .brand #popup.pull-popup .popup-header .tit:lang(ko), .brand .popup.pull-popup .popup-header .tit:lang(ko) {
            font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand #popup.pull-popup .popup-header .tit:lang(en), .brand .popup.pull-popup .popup-header .tit:lang(en) {
            font-family: "NotoSans-Light", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand #popup.pull-popup .popup-header .tit:lang(ja), .brand .popup.pull-popup .popup-header .tit:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 300; }
          .brand #popup.pull-popup .popup-header .tit:lang(zh), .brand .popup.pull-popup .popup-header .tit:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 300; }
          .brand #popup.pull-popup .popup-header .tit em:lang(ko), .brand .popup.pull-popup .popup-header .tit em:lang(ko) {
            font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand #popup.pull-popup .popup-header .tit em:lang(en), .brand .popup.pull-popup .popup-header .tit em:lang(en) {
            font-family: "NotoSans-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .brand #popup.pull-popup .popup-header .tit em:lang(ja), .brand .popup.pull-popup .popup-header .tit em:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 900; }
          .brand #popup.pull-popup .popup-header .tit em:lang(zh), .brand .popup.pull-popup .popup-header .tit em:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 900; }
      .brand #popup.pull-popup .popup-content .column-group, .brand .popup.pull-popup .popup-content .column-group {
        display: table;
        table-layout: fixed;
        margin-top: 4.42708vw; }
      .brand #popup.pull-popup .popup-content .column, .brand .popup.pull-popup .popup-content .column {
        display: table-cell;
        width: 550px; }
        .brand #popup.pull-popup .popup-content .column .txt, .brand .popup.pull-popup .popup-content .column .txt {
          font-family: "NotoSans-DemiLight";
          font-size: 18px;
          color: #222;
          word-break: keep-all;
          line-height: 1.5; }
        .brand #popup.pull-popup .popup-content .column .tag-area, .brand .popup.pull-popup .popup-content .column .tag-area {
          margin-top: 3.125vw; }
        .brand #popup.pull-popup .popup-content .column .tag, .brand .popup.pull-popup .popup-content .column .tag {
          display: inline-block;
          margin: 0 10px 10px 0;
          padding: 5px 20px;
          border: 1px solid #fff;
          border-radius: 25px;
          background: #fff;
          font-family: "NotoSans-DemiLight";
          font-size: 16px;
          line-height: 1.5;
          color: #777;
          box-sizing: border-box; }
        .brand #popup.pull-popup .popup-content .column:first-child, .brand .popup.pull-popup .popup-content .column:first-child {
          padding-right: 100px;
          vertical-align: top; }
        .brand #popup.pull-popup .popup-content .column .gallery-view-img, .brand .popup.pull-popup .popup-content .column .gallery-view-img {
          background: #fff; }
          .brand #popup.pull-popup .popup-content .column .gallery-view-img .img-item, .brand .popup.pull-popup .popup-content .column .gallery-view-img .img-item {
            text-align: center; }
            .brand #popup.pull-popup .popup-content .column .gallery-view-img .img-item .tit, .brand .popup.pull-popup .popup-content .column .gallery-view-img .img-item .tit {
              font-family: "NotoSans-DemiLight";
              font-size: 20px;
              line-height: 1.5;
              color: #222; }
            .brand #popup.pull-popup .popup-content .column .gallery-view-img .img-item img, .brand .popup.pull-popup .popup-content .column .gallery-view-img .img-item img {
              margin-top: 1.5625vw; }
        .brand #popup.pull-popup .popup-content .column .gallery-btn, .brand .popup.pull-popup .popup-content .column .gallery-btn {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          font-size: 0; }
          .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item {
            position: relative;
            display: inline-block;
            width: calc(25% - 8px);
            margin-left: 10px;
            margin-bottom: 0.52083vw;
            transition: 0.3s cubic-bezier(0.66, 0, 0.34, 1) all;
            cursor: pointer; }
            .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item:nth-child(4n+1), .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item:nth-child(4n+1) {
              margin-left: 0vw; }
            .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item button, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item button {
              width: 100%;
              padding: 0; }
              .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item button img, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item button img {
                width: 100%; }
            .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item.on button::after, .brand #popup.pull-popup .popup-content .column .gallery-btn .btn-item:hover button::after, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item.on button::after, .brand .popup.pull-popup .popup-content .column .gallery-btn .btn-item:hover button::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0.3; }
      .brand #popup.pull-popup .popup-content .desc-area, .brand .popup.pull-popup .popup-content .desc-area {
        text-align: center;
        margin-top: 2.60417vw;
        padding: 1.5625vw;
        background: #e4e8ec; }
      .brand #popup.pull-popup .popup-close, .brand .popup.pull-popup .popup-close {
        top: -30px; }
        .brand #popup.pull-popup .popup-close .ico-close, .brand .popup.pull-popup .popup-close .ico-close {
          background-image: url(../../images/common/ico_close_black.png); }
  .brand .ft-txt {
    padding: 20px 0;
    color: #fff;
    border-bottom: 1px solid rgba(204, 204, 204, 0.1);
    background-color: #666666; }
    .brand .ft-txt .noti-area .mgt {
      margin-top: 8px; }
    .brand .ft-txt .noti-area span {
      display: block;
      font-size: 12px;
      line-height: 1.7; }
    .brand .ft-txt .noti-area .point-txt {
      padding-left: 10px;
      text-indent: -10px; }
    .brand .ft-txt .noti-area .sub-txt {
      color: #aaa; }
    .brand .ft-txt .noti-area .desc {
      margin-top: 15px; }
  .brand .reference {
    padding: 20px 0 !important;
    background-color: #d8dcdf; }
    .brand .reference p {
      margin-bottom: 8px;
      text-align: left;
      font-size: 13px; }
    .brand .reference span {
      display: block;
      padding-left: 14px;
      text-indent: -14px;
      text-align: left;
      font-size: 13px;
      line-height: 1.2;
      color: #666; }
      .brand .reference span a {
        font-size: 14px;
        color: #666; }
  /*! 브랜드스토리 공통 */
  .story .sec {
    padding: 100px 0;
    text-align: center;
    overflow: hidden; }
  .story .fix-indi {
    position: fixed;
    top: 220px;
    left: 2.60417vw;
    z-index: 10; }
    .story .fix-indi button {
      cursor: pointer;
      position: relative;
      display: block;
      padding-left: 16px;
      margin-bottom: 20px;
      font-family: "Barlow";
      font-weight: 500;
      color: #999;
      transition: 0.2s ease all; }
      .story .fix-indi button.on {
        color: #222; }
        .story .fix-indi button.on:after {
          content: "";
          display: block;
          clear: both;
          position: absolute;
          top: 50%;
          left: 0;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          border-radius: 100%; }
    .story .fix-indi:hover {
      color: #222; }
    .story .fix-indi.change-other-color button {
      color: rgba(255, 255, 255, 0.5); }
      .story .fix-indi.change-other-color button:hover {
        color: #ffec70; }
        .story .fix-indi.change-other-color button:hover:after {
          background-color: #ffec70; }
      .story .fix-indi.change-other-color button.on {
        color: #ffec70; }
        .story .fix-indi.change-other-color button.on:after {
          background-color: #ffec70; }
    .story .fix-indi.change-white button {
      color: rgba(255, 255, 255, 0.5); }
      .story .fix-indi.change-white button:hover {
        color: #fff; }
        .story .fix-indi.change-white button:hover:after {
          background-color: #fff; }
      .story .fix-indi.change-white button.on {
        color: #fff; }
        .story .fix-indi.change-white button.on:after {
          background-color: #fff; }
  .story .section05 {
    padding-bottom: 140px; }
    .story .section05 .title-wrap {
      margin-bottom: 3.125vw; }
      .story .section05 .title-wrap .sub-txt {
        margin-top: 1.5625vw; }
    .story .section05 .media-cnt {
      position: relative;
      width: 940px;
      margin: auto; }
      .story .section05 .media-cnt .thum-img {
        position: relative;
        width: 724px;
        height: 404px;
        margin: auto;
        cursor: pointer; }
        .story .section05 .media-cnt .thum-img img {
          width: 100%; }
        .story .section05 .media-cnt .thum-img .btn-play {
          width: 102px;
          height: 102px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -51px;
          margin-left: -51px; }
        .story .section05 .media-cnt .thum-img .txt {
          position: absolute;
          left: 30px;
          bottom: 30px;
          font-size: 20px;
          line-height: 1.3;
          color: #fff;
          z-index: 1; }
          .story .section05 .media-cnt .thum-img .txt:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .story .section05 .media-cnt .thum-img .txt:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .story .section05 .media-cnt .thum-img .txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .story .section05 .media-cnt .thum-img .txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
      .story .section05 .media-cnt .pop-cnt {
        display: none; }
      .story .section05 .media-cnt .media-swiper {
        position: relative;
        margin: auto;
        width: 724px;
        height: 404px; }
      .story .section05 .media-cnt .swiper-pagination {
        bottom: -49px; }
      .story .section05 .media-cnt .swiper-button-next,
      .story .section05 .media-cnt .swiper-button-prev {
        width: 3.4375vw;
        height: 3.4375vw;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 50%;
        margin-top: -1.71875vw;
        cursor: pointer; }
      .story .section05 .media-cnt .swiper-button-next {
        background-image: url(../../images/common/ico_next_type03.png);
        right: 0;
        left: auto; }
      .story .section05 .media-cnt .swiper-button-prev {
        background-image: url(../../images/common/ico_prev_type03.png);
        left: 0;
        right: auto; }
  .ui-media-section {
    position: relative; }
    .ui-media-section::after {
      content: "";
      clear: both;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 0%;
      background-color: #f1f5f8;
      opacity: 0;
      transition: 1.35s cubic-bezier(0.66, 0, 0.34, 1) all; }
    .ui-media-section.on::after {
      opacity: 1;
      height: 100%; }
  .product-info .mgt0 {
    margin-top: 0 !important; }
  .product-info .mgt-40 {
    margin-top: -40px !important; }
  .product-info .mgt-22 {
    margin-top: -22px !important; }
  .product-info .title-wrap .sub-tit {
    display: block;
    margin-bottom: 0.88542vw;
    font-size: 20px;
    color: #666; }
    .product-info .title-wrap .sub-tit:lang(ko) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .product-info .title-wrap .sub-tit:lang(en) {
      font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .product-info .title-wrap .sub-tit:lang(ja) {
      font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
    .product-info .title-wrap .sub-tit:lang(zh) {
      font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 700; }
  .product-info .section01 {
    position: relative;
    padding-top: 6.25vw;
    text-align: center;
    background: linear-gradient(#fff 65%, #f1f5f8 35%); }
    .product-info .section01 .inner {
      overflow: hidden;
      max-width: 69.79167vw; }
    .product-info .section01 .swiper-button-next,
    .product-info .section01 .swiper-button-prev {
      opacity: 0;
      position: absolute;
      top: 50%;
      width: 3.4375vw;
      height: 3.4375vw;
      margin-top: 17px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .product-info .section01 .swiper-button-prev,
    .product-info .section01 .swiper-container-rtl .swiper-button-next {
      left: 0;
      right: auto;
      background-image: url(../../images/common/ico_prev_type01.png); }
    .product-info .section01 .swiper-button-next,
    .product-info .section01 .swiper-container-rtl .swiper-button-prev {
      right: 0;
      left: auto;
      background-image: url(../../images/common/ico_next_type01.png); }
    .product-info .section01 .swiper-pagination {
      display: none;
      bottom: 1.5625vw; }
    .product-info .section01 .product-swiper {
      padding-bottom: 4.53125vw;
      max-width: 73.4375vw;
      width: 100%;
      margin: auto; }
      .product-info .section01 .product-swiper .swiper-wrapper {
        position: relative;
        display: flex; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide {
          position: relative;
          width: 20.52083vw;
          height: 20.52083vw;
          margin: auto;
          display: flex;
          flex-shrink: 0;
          border-radius: 100%;
          justify-content: center;
          cursor: pointer; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt {
            position: relative;
            align-self: center;
            z-index: 1; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box {
              display: flex;
              margin: auto;
              min-height: 9.89583vw;
              justify-content: center; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box img {
                align-self: center;
                width: 70%; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt {
              display: block;
              margin-top: 2.08333vw;
              font-size: 20px;
              color: #999; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(ko) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(en) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(ja) {
                font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt:lang(zh) {
                font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
              .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt em {
                display: block;
                font-size: 18px;
                line-height: 1.6; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt.vertical-box img {
              width: 63%; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .active-box {
            opacity: 0;
            position: absolute;
            width: 20.52083vw;
            height: 100%;
            max-width: 394px;
            border-radius: 100%; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt {
            margin-top: 1.04167vw;
            font-size: 1.25vw;
            color: #fff; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt:lang(ko) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt:lang(en) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
            .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt em {
              display: block;
              font-size: 1.14583vw;
              line-height: 1.6; }
          .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .active-box {
            opacity: 1;
            background-color: #238754;
            box-shadow: 15px 15px 15px 0 rgba(0, 0, 0, 0.1); }
        .product-info .section01 .product-swiper .swiper-wrapper.product2 {
          justify-content: center; }
          .product-info .section01 .product-swiper .swiper-wrapper.product2 .swiper-slide {
            margin: 0 18px;
            cursor: pointer; }
            .product-info .section01 .product-swiper .swiper-wrapper.product2 .swiper-slide.on .active-box {
              opacity: 1; }
            .product-info .section01 .product-swiper .swiper-wrapper.product2 .swiper-slide .product-cnt .img-box {
              min-height: auto; }
            .product-info .section01 .product-swiper .swiper-wrapper.product2 .swiper-slide .product-cnt .txt {
              margin-top: 0; }
  .product-info .section02 {
    padding-bottom: 100px;
    background-color: #f1f5f8; }
    .product-info .section02 .product-cnt {
      display: none; }
      .product-info .section02 .product-cnt.on {
        display: block; }
    .product-info .section02 .product-detail-wrap {
      background-color: #fff; }
      .product-info .section02 .product-detail-wrap .title {
        display: flex;
        padding: 2.1875vw 0 1.04167vw;
        border-bottom: 2px solid #222; }
        .product-info .section02 .product-detail-wrap .title .tit-box {
          align-self: center; }
          .product-info .section02 .product-detail-wrap .title .tit-box .ico {
            display: inline-block;
            width: 24px;
            height: 27px;
            margin-right: 10px;
            background-image: url(../../images/common/sprite_pc.png);
            background-position: -129px -84px;
            width: 24px;
            height: 24px;
            background-size: 219px 211px;
            vertical-align: middle; }
          .product-info .section02 .product-detail-wrap .title .tit-box .txt {
            display: inline-block;
            font-size: 24px;
            vertical-align: middle; }
            .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(ko) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(en) {
              font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
            .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(ja) {
              font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
            .product-info .section02 .product-detail-wrap .title .tit-box .txt:lang(zh) {
              font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
              font-weight: 700; }
        .product-info .section02 .product-detail-wrap .title a {
          display: block;
          margin-left: auto;
          padding: 12px 30px 13px;
          background-color: #238754;
          border-radius: 25px; }
          .product-info .section02 .product-detail-wrap .title a .ico {
            display: inline-block;
            width: 15px;
            height: 20px;
            margin-right: 10px;
            vertical-align: middle;
            background-image: url(../../images/common/sprite_pc.png);
            background-position: -120px -129px;
            width: 15px;
            height: 17px;
            background-size: 219px 211px; }
          .product-info .section02 .product-detail-wrap .title a .txt {
            display: inline-block;
            vertical-align: middle;
            color: #fff; }
      .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list {
        display: flex;
        margin-top: 14px;
        border-bottom: 1px dotted #ddd; }
        .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-list-wrap {
          width: 70%; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-list-wrap .detail-list:last-child {
            border-bottom: 0; }
        .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper {
          width: 30%;
          margin-top: 30px;
          position: relative;
          overflow: hidden; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-wrapper {
            display: flex; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-slide {
            flex-shrink: 0;
            width: calc(100% - 32px); }
            .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-slide img {
              width: 100%; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination {
            opacity: 1;
            text-align: center; }
            .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination span {
              font-family: "Barlow";
              font-weight: 500;
              font-size: 20px;
              color: #ccc; }
              .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-pagination span.swiper-pagination-current {
                color: #222; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev {
            width: 9px;
            height: 14px;
            position: absolute;
            bottom: 1.82292vw;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center; }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev,
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-container-rtl .swiper-button-next {
            left: 145px;
            right: auto;
            background-image: url(../../images/common/ico_prev_type04.png); }
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
          .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-container-rtl .swiper-button-prev {
            right: 145px;
            left: auto;
            background-image: url(../../images/common/ico_next_type04.png); }
      .product-info .section02 .product-detail-wrap .detail-wrap .detail-list {
        padding: 40px 20px;
        border-bottom: 2px dotted #ddd; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top {
          display: flex; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 20px;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-position: center; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico01 {
              background-image: url(../../images/brand/ico_detail01.png);
              background-size: 60px 48px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico02 {
              background-image: url(../../images/brand/ico_detail02.png);
              background-size: 39px 52px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico03 {
              background-image: url(../../images/brand/ico_detail03.png);
              background-size: 47px 49px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico04 {
              background-image: url(../../images/brand/ico_detail04.png);
              background-size: 38px 55px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico05 {
              background-image: url(../../images/brand/ico_detail05.png);
              background-size: 28px 58px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico06 {
              background-image: url(../../images/brand/ico_detail06.png);
              background-size: 47px 42px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico07 {
              background-image: url(../../images/brand/ico_detail07.png);
              background-size: 47px 47px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico08 {
              background-image: url(../../images/brand/ico_detail08.png);
              background-size: 50px 50px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico09 {
              background-image: url(../../images/brand/ico_detail09.png);
              background-size: 37px 46px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico10 {
              background-image: url(../../images/brand/ico_detail10.png);
              background-size: 38px 43px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico11 {
              background-image: url(../../images/brand/ico_detail11.png);
              background-size: 37px 47px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico12 {
              background-image: url(../../images/brand/ico_detail12.png);
              background-size: 41px 40px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico13 {
              background-image: url(../../images/brand/ico_detail13.png);
              background-size: 47px 42px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico14 {
              background-image: url(../../images/brand/ico_detail14.png);
              background-size: 45px 50px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico15 {
              background-image: url(../../images/brand/ico_detail15.png);
              background-size: 45px 46px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico16 {
              background-image: url(../../images/brand/ico_detail16.png);
              background-size: 40px 51px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top > .ico.ico17 {
              background-image: url(../../images/brand/ico_detail17.png);
              background-size: 46px 44px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box {
            width: calc(100% - 80px); }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit {
              display: block;
              margin-bottom: 5px;
              font-size: 20px;
              line-height: 1.6; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(ko) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(en) {
                font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(ja) {
                font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .tit:lang(zh) {
                font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                font-weight: 700; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt {
              line-height: 1.5;
              color: #666;
              font-size: 18px; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt .sub-tit {
                display: block;
                font-size: 18px; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .txt .sub-txt {
                display: block;
                word-break: keep-all;
                color: #666; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .img-txt {
              display: block;
              margin-top: 20px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .product-box {
              display: table;
              width: 100%;
              margin-top: 20px; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .product-box .product {
                display: table-cell;
                width: 33.333333%;
                text-align: center; }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .product-box .product img {
                  display: block;
                  margin: auto; }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .product-box .product .txt {
                  display: block;
                  margin-top: 15px;
                  color: #666;
                  font-size: 18px;
                  letter-spacing: -1px; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-top .txt-box .product-box.type02 .product {
                width: 25%; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .img-txt {
          display: block;
          margin-top: 30px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box {
          display: block;
          margin-top: 30px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box span {
            display: block;
            word-break: keep-all;
            font-size: 16px;
            line-height: 1.6;
            color: #222; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc-t {
            color: #222;
            margin-bottom: 18px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc {
            position: relative;
            padding-left: 16px;
            line-height: 1.7; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box .desc::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              width: 4px;
              height: 4px;
              margin-top: -2px;
              background: #666; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box.mt-18 {
            margin-top: 18px; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .desc-box-type02 .desc {
          word-break: keep-all;
          font-size: 18px;
          line-height: 1.6;
          color: #666; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm {
          margin-left: 80px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap {
            display: flex;
            width: 100%;
            margin-top: 16px;
            background-color: #f1f5f8; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt {
              flex: auto;
              width: 33.333333%;
              padding: 0 30px;
              margin: 20px 0;
              box-sizing: border-box;
              border-right: 1px solid #ddd; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span {
                display: block; }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.content {
                  word-break: keep-all;
                  line-height: 1.6;
                  color: #666; }
                  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.content em {
                    display: block;
                    font-size: 14px;
                    line-height: 1.6;
                    color: #666; }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico {
                  display: inline-block;
                  width: 27px;
                  height: 27px;
                  background-repeat: no-repeat;
                  background-position: center top;
                  vertical-align: middle; }
                  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico01 {
                    margin-right: 9px;
                    background-size: 25px 24px;
                    background-image: url(../../images/brand/ico_detail03_01.png); }
                  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico02 {
                    background-size: 15px 26px;
                    background-image: url(../../images/brand/ico_detail03_02.png); }
                  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico03 {
                    background-size: 18px 26px;
                    background-image: url(../../images/brand/ico_detail03_03.png); }
                  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico04 {
                    background-size: 24px 24px;
                    background-image: url(../../images/brand/ico_detail03_04.png); }
                  .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .ico.ico05 {
                    background-size: 24px 24px;
                    background-image: url(../../images/brand/ico_detail03_05.png); }
                .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt span.name .txt {
                  display: inline-block;
                  vertical-align: middle;
                  color: #666;
                  line-height: 1.6; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap .cnt:last-child {
                border-right: 0; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type02 {
              margin-top: 22px;
              padding: 22px 0; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type02 .cnt {
                width: auto;
                margin: 0;
                padding: 0 0 0 20px; }
            .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type03 {
              margin-top: 22px;
              padding: 22px 0; }
              .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .box-wrap.type03 .cnt {
                width: auto;
                margin: 0;
                padding: 0 0 0 20px; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .sub-tit {
            display: block;
            margin-top: 30px;
            font-size: 18px;
            line-height: 1.5; }
          .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .detail-btm .sub-txt {
            display: block;
            word-break: keep-all;
            color: #666;
            line-height: 1.5; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list .list {
          display: block;
          padding-left: 10px;
          text-indent: -10px;
          line-height: 1.8;
          word-break: keep-all;
          color: #666; }
        .product-info .section02 .product-detail-wrap .detail-wrap .detail-list:last-child {
          border-bottom: 0; }
      .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 {
        width: 100%;
        margin-top: 30px; }
        .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 thead {
          background: #f1f5f8; }
        .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th, .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 td {
          text-align: center;
          padding: 15px 0;
          color: #666;
          font-family: "NotoSans-DemiLight";
          word-break: break-all;
          border: 1px solid #ddd; }
          .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th:first-child, .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 td:first-child {
            border-left: none; }
          .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th:last-child, .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 td:last-child {
            border-right: none; }
        .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th {
          color: #222;
          border-top: 1px solid #888; }
          .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .product-info .section02 .product-detail-wrap .detail-wrap table.table-type01 th:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
    .product-info .section02 .precautions {
      padding: 30px 0;
      text-align: center;
      background-color: #666; }
      .product-info .section02 .precautions .txt {
        color: #fff;
        line-height: 1.5; }
      .product-info .section02 .precautions .btn-download {
        display: block;
        width: 300px;
        margin: 20px auto 0;
        padding: 20px 0;
        background-color: #fff;
        border-radius: 30px; }
        .product-info .section02 .precautions .btn-download .txt {
          display: inline-block;
          font-size: 20px;
          line-height: 1.2;
          color: #222;
          vertical-align: middle; }
          .product-info .section02 .precautions .btn-download .txt:lang(ko) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .precautions .btn-download .txt:lang(en) {
            font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
          .product-info .section02 .precautions .btn-download .txt:lang(ja) {
            font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
          .product-info .section02 .precautions .btn-download .txt:lang(zh) {
            font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: 700; }
        .product-info .section02 .precautions .btn-download .ico {
          display: inline-block;
          width: 18px;
          height: 20px;
          margin-right: 10px;
          background-image: url(../../images/common/sprite_pc.png);
          background-position: -92px -129px;
          width: 18px;
          height: 17px;
          background-size: 219px 211px;
          vertical-align: middle; }
  .product-info .section03 {
    padding: 60px 0;
    background-color: #333; }
    .product-info .section03 .title-wrap {
      text-align: center; }
      .product-info .section03 .title-wrap .related-tit {
        display: block;
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 1.2;
        color: #fff; }
        .product-info .section03 .title-wrap .related-tit:lang(ko) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .product-info .section03 .title-wrap .related-tit:lang(en) {
          font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        .product-info .section03 .title-wrap .related-tit:lang(ja) {
          font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
        .product-info .section03 .title-wrap .related-tit:lang(zh) {
          font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-weight: 700; }
    .product-info .section03 .related-swiper {
      overflow: hidden;
      width: 902px !important;
      margin: auto; }
      .product-info .section03 .related-swiper .swiper-wrapper {
        display: flex; }
        .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide {
          flex-shrink: 0;
          width: 282px !important; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide a {
            display: block; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .img-box img {
            width: 100%; }
          .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box {
            margin-top: 30px; }
            .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span {
              display: block;
              text-align: center; }
              .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit {
                margin-bottom: 15px;
                font-size: 20px;
                color: #fff; }
                .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit:lang(ko) {
                  font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
                .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit:lang(en) {
                  font-family: "NotoSans-Medium", Arial, "Helvetica Neue", Helvetica, sans-serif; }
                .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit:lang(ja) {
                  font-family: "Noto+Sans+JP", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  font-weight: 700; }
                .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.tit:lang(zh) {
                  font-family: "Noto+Sans+SC", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  font-weight: 700; }
              .product-info .section03 .related-swiper .swiper-wrapper .swiper-slide .txt-box span.txt {
                color: #999;
                line-height: 1.6; }
      .product-info .section03 .related-swiper.product2 .swiper-wrapper {
        justify-content: center; }
        .product-info .section03 .related-swiper.product2 .swiper-wrapper .swiper-slide + .swiper-slide {
          margin-left: 30px; }
    .product-info .section03 .swiper-button-next,
    .product-info .section03 .swiper-button-prev {
      position: absolute;
      top: 50%;
      width: 3.4375vw;
      height: 3.4375vw;
      margin-top: -1.71875vw;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .product-info .section03 .swiper-button-prev,
    .product-info .section03 .swiper-container-rtl .swiper-button-next {
      left: 0;
      right: auto;
      background-image: url(../../images/common/ico_prev_type02.png); }
    .product-info .section03 .swiper-button-next,
    .product-info .section03 .swiper-container-rtl .swiper-button-prev {
      right: 0;
      left: auto;
      background-image: url(../../images/common/ico_next_type02.png); }
    .product-info .section03 .swiper-pagination {
      bottom: -50px; } }

@media (min-width: 1024px) and (min-width: 1920px) {
  .story .section05 .media-cnt {
    position: relative;
    width: 940px;
    margin: auto; }
    .story .section05 .media-cnt .swiper-button-next,
    .story .section05 .media-cnt .swiper-button-prev {
      width: 66px;
      height: 66px;
      margin-top: -33px; }
    .story .section05 .media-cnt .swiper-button-next {
      right: 0; }
    .story .section05 .media-cnt .swiper-button-prev {
      left: 0; }
  .product-info .section01 {
    padding-top: 120px; }
    .product-info .section01 .inner {
      overflow: hidden;
      max-width: 1340px; }
    .product-info .section01 .swiper-button-next,
    .product-info .section01 .swiper-button-prev {
      width: 66px;
      height: 66px;
      margin-top: 17px; }
    .product-info .section01 .swiper-button-prev,
    .product-info .section01 .swiper-container-rtl .swiper-button-next {
      left: 0; }
    .product-info .section01 .swiper-button-next,
    .product-info .section01 .swiper-container-rtl .swiper-button-prev {
      right: 0; }
    .product-info .section01 .product-swiper {
      padding-bottom: 87px; }
      .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide {
        width: 394px;
        height: 394px; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box {
          min-height: 190px; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .txt {
          margin-top: 40px;
          font-size: 20px; }
        .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide.on .product-cnt .txt {
          font-size: 24px; }
  .product-info .section03 .swiper-button-next,
  .product-info .section03 .swiper-button-prev {
    width: 66px;
    height: 66px;
    margin-top: -33px; }
  .product-info .section03 .swiper-pagination {
    bottom: -50px; } }

@media screen and (min-width: 1024px) and (min-width: 1024px) and (max-width: 1340px) {
  .product-info .section01 .swiper-button-prev,
  .product-info .section01 .swiper-container-rtl .swiper-button-next {
    left: 10px; }
  .product-info .section01 .swiper-button-next,
  .product-info .section01 .swiper-container-rtl .swiper-button-prev {
    right: 10px; } }

@media screen and (min-width: 1024px) and (min-width: 1024px) and (max-width: 1200px) {
  .brand .swiper-pagination {
    bottom: 40px; }
  .product-info .section01 .swiper-button-prev,
  .product-info .section01 .swiper-container-rtl .swiper-button-next {
    left: 10px; }
  .product-info .section01 .swiper-button-next,
  .product-info .section01 .swiper-container-rtl .swiper-button-prev {
    right: 10px; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt.vertical-box .img-box img {
    width: 54%;
    margin: auto; }
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev {
    bottom: 40px; }
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-prev,
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-container-rtl .swiper-button-next {
    left: 38%; }
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-button-next,
  .product-info .section02 .product-detail-wrap .detail-wrap .swiper-list .detail-swiper .swiper-container-rtl .swiper-button-prev {
    right: 38%; }
  .product-info .section03 .swiper-button-prev,
  .product-info .section03 .swiper-container-rtl .swiper-button-next {
    left: 10px; }
  .product-info .section03 .swiper-button-next,
  .product-info .section03 .swiper-container-rtl .swiper-button-prev {
    right: 10px; }
  #container .pull-popup .popup-inner {
    width: 100%; }
  #container .pull-popup .popup-close {
    right: 40px; } }

@media all and (min-width: 1024px) and (-ms-high-contrast: none), (min-width: 1024px) and (-ms-high-contrast: active) {
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt {
    width: 100%; }
    .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt.vertical-box {
      width: auto; }
    .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .product-cnt .img-box {
      min-height: auto; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide .active-box {
    left: 50%;
    transform: translateX(-50%) !important; }
  .product-info .section01 .product-swiper .swiper-wrapper .swiper-slide:nth-child(3) .product-cnt .img-box img {
    width: 60%; } }

@media (min-width: 1024px) {
  .shakeThis {
    animation: shakeMotion 0.4s ease-in forwards 1.1s; }
  @keyframes shakeMotion {
    0% {
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    12% {
      -o-transform: rotate(2deg);
      -ms-transform: rotate(2deg);
      -moz-transform: rotate(2deg);
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg); }
    36% {
      -o-transform: rotate(-2deg);
      -ms-transform: rotate(-2deg);
      -moz-transform: rotate(-2deg);
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg); }
    62% {
      -o-transform: rotate(1deg);
      -ms-transform: rotate(1deg);
      -moz-transform: rotate(1deg);
      -webkit-transform: rotate(1deg);
      transform: rotate(1deg); }
    86% {
      -o-transform: rotate(-1deg);
      -ms-transform: rotate(-1deg);
      -moz-transform: rotate(-1deg);
      -webkit-transform: rotate(-1deg);
      transform: rotate(-1deg); }
    100% {
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); } } }
