@charset "utf-8";

/*! contents - mobile */
[class*="web-"] {
  display: none !important;
}
/*! 브랜드 공통 */
.brand {
  overflow: hidden;
  
  .sec {
    padding: get-vw-mo(150px) 0;
    overflow: hidden;

    &.section01 {
      padding-top: get-vw-mo(100px);
    }
  }

  // 주석 공통
  .ref-txt {
    position: relative;
    font-style: normal;
    line-height: 1.5;
    // 서브타이틀
    top: get-vw-mo(-13px);
    left: get-vw-mo(-2px);
    font-size: get-vw-mo(18px);

    // 대타이틀
    &.big {
      top: get-vw-mo(-40px);
      left: get-vw-mo(-2px);
      font-size: get-vw-mo(26px);
    }
  }

  .title-wrap {
    text-align: center;
    .title {
      margin-bottom: get-vw-mo(60px);
      span {
        display: block;
        letter-spacing: get-vw-mo(-2px);
        // font-family: $NotoSansL;
        @include notoSansL();
        font-size: get-vw-mo(62px);
        line-height: 1.3;
        em {
          letter-spacing: get-vw-mo(-4px);
          @include font($NotoSansB, get-vw-mo(62px), 1.3, #238754);
          @include notoSansB();
        }
      }
    }
    .tag-wrap {
      margin: get-vw-mo(18px) 0 get-vw-mo(30px);
      .tag {
        display: inline-block;
        padding: get-vw-mo(16px) get-vw-mo(28px);
        margin-bottom: get-vw-mo(20px);
        border-radius: get-vw-mo(35px);
        background: #fff;
        & + .tag {
          margin-left: get-vw-mo(15px);
        }
        font-size: get-vw-mo(28px);
        color: #777;
      }
    }
    
    .tit {
      width: get-vw-mo(420px);
      margin: get-vw-mo(30px) auto get-vw-mo(50px);
      img {
        width: 100%;
      }
    }
    .sub-txt {
      max-width: 800px;
      margin: auto;
      span {
        display: block;
        word-break: keep-all;
        font-size: get-vw-mo(28px);
        line-height: 1.5;
        
      }
    }
  }
  .sub-tit {
    @include text-overflow-multi(2);
    // font-family: $NotoSansL;
    @include notoSansL();
    font-size: get-vw-mo(38px);
    line-height: 1.3;
  }
  
  .mo-title {
    display: block;
    margin-bottom: get-vw-mo(30px);
    // font-family: $NotoSansM;
    @include notoSansM();
    font-size: get-vw-mo(28px);
    color: #000;
  }
  .swiper-pagination {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    .swiper-pagination-bullet {
      display: inline-block;
      width: get-vw-mo(16px);
      height: get-vw-mo(16px);
      margin: 0 get-vw-mo(10px);
      border-radius: 100%;
      background-color: #cccccc;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #238754;
    }
  }
  #popup, .popup {
    display: none;
    z-index: 100;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;left: 0;
    &.youtube {
      .popup-wrap {
        width: 90%;
        height: get-vw-mo(550px);
        position: fixed;
        top: 50%;left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.img-pop {
      .popup-wrap {
        position: fixed;
        top: 50%;left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 90%;
        max-height: 60%;
        height: auto;
        img {
          width: 100%;
        }
      }
    }
    .popup-close {
      position: absolute;
      top: get-vw-mo(-72px);
      right: get-vw-mo(20px);
      .ico-close {
        display: inline-block;
        width: get-vw-mo(42px);
        height: get-vw-mo(42px);
        background: url(../../images/common/ico_close_white.png) no-repeat center;
        background-size: contain;
      }
    }
    &.pull-popup{
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      transform: none;
      background: #f1f5f8;
      overflow-y: scroll;
      .popup-inner{position: relative;padding: 0 get-vw-mo(40px);}
      .popup-header{
        margin-top: get-vw-mo(120px);
        .since-txt{@include font($NotoSansL, get-vw-mo(30px), 1.4, #666);}
        .tit{
          @include font($NotoSansL, get-vw-mo(50px), 1.4, #222);
          em{
            @include notoSansB();
          }
        }
      }
    .popup-content{
        .column{
          .txt-box{
            margin-top: get-vw-mo(50px);
            // height: get-vw-mo(230px);
            padding-right: get-vw-mo(30px);
            overflow-y: auto;
            word-break: keep-all;
            @include font($NotoSansL, get-vw-mo(28px), 1.5, #222);
          }
          .img-box {
            text-align: center;
            img {
              width: 80%;
            }
          }
          .tag-area{
            margin-top: get-vw-mo(40px);
            .tag {
              display: inline-block;
              margin-right: get-vw-mo(10px);
              padding: get-vw-mo(16px) get-vw-mo(28px);
              margin-bottom: get-vw-mo(20px);
              border-radius: get-vw-mo(35px);
              background: #fff;
              font-size: get-vw-mo(28px);
            }
          }
          .gallery-view-area{margin-top: get-vw-mo(30px);}
          .gallery-view-img{
            .img-item{
              img{width: 100%;}
              .tit{text-align: center;}
            }
          }
          .gallery-btn{
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: get-vw-mo(20px);
            font-size: 0;
            .btn-item{
              position: relative;
              display: inline-block;
              width: get-vw-mo(154px);
              margin-left:get-vw-mo(17px);
              margin-bottom:get-vw-mo(17px);
              &:nth-child(4n+1){margin-left:get-vw-mo(0px);}
              &.on{
                button{
                  &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: 0.3;
                  }
                }
              }
            }
            button{width: 100%;padding: 0;}
            img{width: 100%;}
          }
        }
        &.type02 {
          .column {
            .txt-box {
              height: auto;
            }
            .img-box {
              text-align: center;
              img {
                width: 70%;
              }
            }
          }
        }
      }
      .popup-close{
        // right: 20px;
        .ico-close{
          background-image: url(../../images/common/ico_close_black.png);
        }
      }
    }
  }
  .ft-txt {
    padding: get-vw-mo(30px) 0;
    color: #fff;
    border-bottom: 1px solid rgba(204,204,204,0.1);
    background-color: #666666;
    .noti-area {
      
      .mgt,.mgt-mo {margin-top: get-vw-mo(12px);}
      

      span {
        display: block;
        font-size: get-vw-mo(24px);
        line-height: 1.5;
        &.point-txt {
          padding-left: get-vw-mo(12px);
          text-indent: get-vw-mo(-16px);
        }
      }

      .sub-txt {
        color:#aaa;
      }

      .desc{margin-top: get-vw-mo(20px);}
    }
  }
  .reference{
    padding: get-vw-mo(30px) 0;
    background-color: #d8dcdf;
    p {
      text-align: left;
      font-size: get-vw-mo(24px);
      line-height: 1.5;
    }
    span {
      display: block;
      text-align: left;
      font-size: get-vw-mo(24px);
      line-height: 1.2;
      color: #666;
      a {
        font-size: get-vw-mo(24px);
        line-height: 1.5;
        color: #666;
      }
    }
  }
}

/*! 브랜드스토리 공통 */
.story {
  .sec {
    padding: get-vw-mo(150px) 0;
    text-align: center;

    &.reference {
      padding: get-vw-mo(30px) 0;
    }
  }
}
.section05 {
  padding-bottom: get-vw-mo(157px);
  background-color: #f1f5f8;
  .title-wrap {
    margin-bottom: get-vw-mo(70px);
    .sub-txt {
      margin-top: get-vw-mo(40px);
    }
  }
  .media-cnt {
    .thum-img {
      position: relative;
      width: 100%;
      margin: auto;
      cursor: pointer;
      .btn-play {
        width: get-vw-mo(90px);
        height: get-vw-mo(90px);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: get-vw-mo(-45px);
        margin-left: get-vw-mo(-45px);
      }
      img {
        width: 100%;
      }
      .txt {
        position: absolute;
        left: get-vw-mo(20px);
        bottom: get-vw-mo(20px);
        font-family: $NotoSansM;
        font-size: get-vw-mo(20px);
        line-height: 1.3;
        color: #fff;
        z-index: 1;
      }
    }
    .pop-cnt {
      display: none;
    }
    .media-swiper {
      position: relative;
      margin: auto;
      width: 100%;
      min-height: get-vw-mo(394px);
    }
    .swiper-pagination {
      bottom: get-vw-mo(-60px);
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
.section06{
  background-color: #666;
  padding: get-vw-mo(40px) 0;
  border-bottom: 1px solid #707070;
  .noti-area{
    span{
      display: block;
      @include font($NotoSansL, get-vw-mo(28px), 1.5, #fff);
    }
    .point-txt{
      text-indent: get-vw-mo(-13px);
      padding-left:get-vw-mo(12px);
    }
  }
}

// 제품정보
.product-info {
  
  .title-wrap {
    .sub-tit {
      display: block;
      margin-bottom: get-vw(40px);
      // font-family: $NotoSansM;
      @include notoSansM();
      font-size: get-vw-mo(30px);
      color: #666;
    }
  }
  .section01 {
    padding-top: get-vw-mo(100px);
    text-align: center;
    background: linear-gradient(#fff 56%, #f1f5f8 44%);
    .product-swiper {
      padding-bottom: get-vw-mo(150px);
      .swiper-wrapper {
        position: relative;
        display: flex;
        .swiper-slide {
          flex-shrink: 0;
          max-width: 554px;
          // margin: auto;
          width: get-vw-mo(554px) !important;
          height: get-vw-mo(554px);
          // margin: 0 get-vw-mo(40px);
          border-radius: 100%;
          justify-content: center;
          opacity: 0;

          .product-cnt {
            width: 90%;
            margin-top: get-vw-mo(10px);
            align-self: center;
            position: relative;
            z-index: 1;
            &.vertical-box {
              .img-box {
                img {
                  width: 40%;
                  margin: 0 auto;
                }
              }
            }
            .img-box {
              display: flex;
              margin: auto;
              justify-content: center;
              img {
                align-self: center;
                width: 80%;
              }
            }
            .txt {
              display: block;
              margin-top: get-vw-mo(40px);
              // font-family: $NotoSansM;
              @include notoSansM();
              font-size: get-vw-mo(36px);
              color: #fff;
              &.mgt0 {
                margin-top: get-vw-mo(-35px);
              }
              em {
                display: block;
                margin-top: get-vw-mo(4px);
                font-size: get-vw-mo(28px);
              }
            }
          }
          .active-box {
            opacity: 0;
            position: absolute;
            // left: 0;
            transform: translateX(0) !important;
            width: get-vw-mo(554px);
            height: get-vw-mo(554px);
            margin: auto;
            border-radius: 100%;
          }
          &.on {
            position: relative;
            display: flex;
            opacity: 1;
            margin: auto;
            .active-box {
              opacity: 1;
              background-color: #238754;
              box-shadow: 15px 15px 15px 0 rgba(0, 0, 0, 0.1);
          }
          }

        }
      }
      &.vertical-type{
        .swiper-wrapper {
          .swiper-slide.on {
            .product-cnt{
              width: initial; margin-top: 0;
              .txt{margin-top: 0;}
              .img-box{width: 100%;}
            }
          }
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: block;
      width: get-vw-mo(35px);height: get-vw-mo(66px);
      background: url(../../images/mo/brand/mo_product_arr.png) no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      margin-top: get-vw-mo(-20px);
      cursor: pointer;
    }
    .swiper-button-prev {
      left: get-vw-mo(30px);
    }
    .swiper-button-next {
      right: get-vw-mo(30px);
      transform: rotate(180deg);
    }
    .swiper-pagination {
      left: 0;
      right: 0;
      bottom: get-vw-mo(80px);
    }
  }
  .section02 {
    .product-cnt {
      display: none;
      &.on {
        display: block;
      }
    }
    .product-detail-wrap {
      background-color: #fff;
      .title {
        display: flex;
        padding: get-vw-mo(73px) 0 get-vw(30px);
        border-bottom: 1px solid #222;
        box-sizing: border-box;
        .tit-box {
          align-self: center;
          .ico {
            display: inline-block;
            width: get-vw-mo(36px);
            height: get-vw-mo(36px);
            margin-right: get-vw-mo(10px);
            background: url(../../images/mo/ico_detail.png);
            background-size: cover;
            vertical-align: middle;
          }
          .txt {
            display: inline-block;
            // font-family: $NotoSansM;
            @include notoSansM();
            font-size: get-vw-mo(34px);
            vertical-align: middle;
          }
        }
        a {
          display: block;
          margin-left: auto;
          min-height: get-vw-mo(76px);
          padding: get-vw-mo(22px) get-vw-mo(40px);
          background-color: #238754;
          border-radius: get-vw-mo(40px);
          box-sizing: border-box;
          .ico {
            display: inline-block;
            width: get-vw-mo(23px);
            height: get-vw-mo(26px);
            margin-right: get-vw-mo(12px);
            vertical-align: top;
            background: url(../../images/mo/icon_down.png);
            background-size: contain;

          }
          .txt {
            display: inline-block;
            vertical-align: top;
            font-size: get-vw-mo(28px);
            color: #fff;
          }
        }
      }
      .detail-wrap {
        .swiper-list {
          .detail-list-wrap {
            .detail-list:last-child {
              border-bottom: 0;
            }
          }
          .detail-swiper {
            padding-bottom: get-vw-mo(120px);
            position: relative;
            border-bottom: 1px dotted #ddd;
            overflow: hidden;
            .swiper-wrapper {
              display: flex;
            }
            .swiper-slide {
              width: 100%;
              flex-shrink: 0;
              // margin-right: get-vw-mo(40px);
              img {
                width: 100%;
              }
            }
            .swiper-pagination {
              margin-top: get-vw-mo(30px);
              text-align: center;
              span {
                @include fontE($fontEng, 500);
                font-size: get-vw-mo(30px);
                color: #ccc;
                &.swiper-pagination-current {
                  color: #222;
                }
              }
            }
            .swiper-button-next,
            .swiper-button-prev {
              width: get-vw-mo(11px);
              height: get-vw-mo(25px);
              position: absolute;
              bottom: get-vw-mo(60px);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }
            .swiper-button-prev,
            .swiper-container-rtl .swiper-button-next {
              left: 40%;
              right: auto;
              background-image: url(../../images/common/ico_prev_type04.png);
            }
            .swiper-button-next,
            .swiper-container-rtl .swiper-button-prev {
              right: 40%;
              left: auto;
              background-image: url(../../images/common/ico_next_type04.png);
            }
          }
        }
        .detail-list {
          padding: get-vw-mo(50px) 0;
          border-bottom: get-vw-mo(3px) dotted #ddd;
          .detail-top {
            display: flex;
            > .ico {
              display: inline-block;
              width: 14%;
              height: get-vw-mo(87px);
              margin-right: get-vw-mo(30px);
              vertical-align: middle;
              background-repeat: no-repeat;
              background-position: center;
              &.ico01 {
                background-image: url(../../images/brand/ico_detail01.png);
                background-size: get-vw-mo(90px) get-vw-mo(72px);
              }
              &.ico02 {
                background-image: url(../../images/brand/ico_detail02.png);
                background-size: get-vw-mo(62px) get-vw-mo(82px);
              }
              &.ico03 {
                background-image: url(../../images/brand/ico_detail03.png);
                background-size: contain;
              }
              &.ico04 {
                background-image: url(../../images/brand/ico_detail04.png);
                background-size: get-vw-mo(58px) get-vw-mo(84px);
              }
              &.ico05 {
                background-image: url(../../images/brand/ico_detail05.png);
                background-size: get-vw-mo(42px) get-vw-mo(87px);
              }
              &.ico06 {
                background-image: url(../../images/brand/ico_detail06.png);
                background-size: get-vw-mo(71px) get-vw-mo(64px);
              }
              &.ico07 {
                background-image: url(../../images/brand/ico_detail07.png);
                background-size: get-vw-mo(71px) get-vw-mo(71px);
              }
              &.ico08 {
                background-image: url(../../images/brand/ico_detail08.png);
                background-size: get-vw-mo(75px) get-vw-mo(75px);
              }
              &.ico09 {
                background-image: url(../../images/brand/ico_detail09.png);
                background-size: get-vw-mo(45px) get-vw-mo(65px);
              }
              &.ico10 {
                background-image: url(../../images/brand/ico_detail10.png);
                background-size: get-vw-mo(60px) get-vw-mo(68px);
              }
              &.ico11 {
                background-image: url(../../images/brand/ico_detail11.png);
                background-size:get-vw-mo(55px) get-vw-mo(70px);
              }
              &.ico12 {
                background-image: url(../../images/brand/ico_detail12.png);
                background-size: get-vw-mo(70px) get-vw-mo(72px);
              }
              &.ico13 {
                background-image: url(../../images/brand/ico_detail13.png);
                background-size:get-vw-mo(47px) get-vw-mo(42px);
              }
              &.ico14 {
                background-image: url(../../images/brand/ico_detail14.png);
                background-size: get-vw-mo(71px) get-vw-mo(78px);
              }
              &.ico15 {
                background-image: url(../../images/brand/ico_detail15.png);
                background-size: get-vw-mo(66px) get-vw-mo(66px);
              }
              &.ico16 {
                background-image: url(../../images/brand/ico_detail16.png);
                background-size:get-vw-mo(60px) get-vw-mo(75px);
              }
              &.ico17 {
                background-image: url(../../images/brand/ico_detail17.png);
                background-size: get-vw-mo(67px) get-vw-mo(65px);
              }
            }
            .txt-box {
              width: 86%;
              align-self: center;
              .tit {
                display: block;
                margin-bottom: get-vw-mo(10px);
                // font-family: $NotoSansM;
                @include notoSansM();
                font-size: get-vw-mo(30px);
                line-height: 1.6;
              }
              .txt {
                line-height: 1.5;
                font-size: get-vw-mo(28px);
                color: #666;
                .sub-tit {
                  display: block;
                  font-family: $NotoSansDL;
                  font-size: get-vw-mo(28px);
                }
                .sub-txt {
                  display: block;
                  font-size: get-vw-mo(26px);
                  color: #666;
                }
              }
              .sub{
                display: block;
                margin-top: get-vw-mo(30px);
                .sub-tit{
                  font-family: $NotoSansDL;
                  font-size: get-vw-mo(30px);
                  color: #222;
                }
                .sub-txt{
                  display: block;
                  font-size: get-vw-mo(26px);
                  color: #666;
                  line-height: 1.6;
                  &:not(:last-child){margin-top: get-vw-mo(10px);}
                }
              }
            }
          }
          .product-box {
            margin-top: get-vw-mo(40px);
            .product {
              margin-bottom: get-vw-mo(40px);
              img {
                display: block;
                margin-bottom: get-vw-mo(25px);
              }
              span {
                display: block;
              }
            }
          }
          .img-txt {
            display: block;
            margin-top: get-vw-mo(30px);
            img {
              width: 100%;
            }
          }
          .desc-box{
            display: block;
            margin-top:get-vw-mo(30px);
            span{
              display: block;
              word-break: keep-all;
              font-size: get-vw-mo(28px);
              line-height: 1.6;
              color: #666;
            }
            .desc-t{color: #222;margin-bottom: get-vw-mo(18px);}
            .desc{
              position: relative;
              padding-left: get-vw-mo(16px);
              line-height: 1.7;
              letter-spacing: -1px;
              &::before{
                content: "";
                position: absolute;
                top: get-vw-mo(20px);
                left: 0;
                width: get-vw-mo(7px);
                height: get-vw-mo(7px);
                background: #666;
                border-radius: 100%;
              }
            }
            &.mt-18{margin-top: get-vw-mo(18px);}
          }
          .desc-box-type02 {
            .desc {
              word-break: keep-all;
              font-size: get-vw-mo(28px);
              line-height: 1.6;
              color: #666;
              padding-left: get-vw-mo(32px);
              text-indent: get-vw-mo(-33px);
            }
          }
          .detail-btm {
            .box-wrap {
              width: 100%;
              margin-top: get-vw-mo(36px);
              background-color: #f1f5f8;
              .cnt {
                flex: auto;
                padding: get-vw-mo(47px) get-vw-mo(30px) 0;
                box-sizing: border-box;
                span {
                  display: block;
                  &.content {
                    word-break: keep-all;
                    font-size: get-vw-mo(28px);
                    line-height: 1.6;
                    color: #666;
                  }
                  &.name {
                    margin-bottom: get-vw-mo(10px);
                    .ico {
                      display: inline-block;
                      width: get-vw-mo(36px);
                      height: get-vw-mo(36px);
                      
                      background-repeat: no-repeat;
                      background-position: center top;
                      vertical-align: middle;
                      &.ico01 {
                        margin-right: get-vw-mo(16px);
                        background-size: get-vw-mo(36px);
                        height: get-vw-mo(36px);
                        background-image: url(../../images/brand/ico_detail03_01.png);
                      }
                      &.ico02 {
                        background-size: get-vw-mo(24px);
                        height: get-vw-mo(41px);
                        background-image: url(../../images/brand/ico_detail03_02.png);
                      }
                      &.ico03 {
                        background-size: get-vw-mo(28px);
                        height: get-vw-mo(40px);
                        background-image: url(../../images/brand/ico_detail03_03.png);
                        
                      }
                      &.ico04 {
                        margin-right: get-vw-mo(15px);
                        background-size: get-vw-mo(39px);
                        height: get-vw-mo(39px);width: get-vw-mo(39px);
                        background-image: url(../../images/brand/ico_detail03_04.png);
                      }
                      &.ico05 {
                        margin-right: get-vw-mo(15px);
                        background-size: get-vw-mo(39px);
                        height: get-vw-mo(39px);width: get-vw-mo(39px);
                        background-image: url(../../images/brand/ico_detail03_05.png);
                      }
                    }
                    .txt {
                      display: inline-block;
                      vertical-align: middle;
                      font-size: get-vw-mo(28px);
                      line-height: 1.6;
                      color: #666;
                    }
                  }
                }
                &:last-child {
                  padding-bottom: get-vw-mo(47px);
                }
              }
              &.type02 {
                .cnt {
                  float: none;
                  width: 100%;
                  & + .cnt {
                    padding-top: 0;
                  }
                }
              }
              &.type03 {
                .cnt {
                  float: none;
                  width: 100%;
                }
              }
            }
            .list {
              display: block;
              padding-left: get-vw-mo(10px);
              text-indent: get-vw-mo(-15px);
              line-height: 1.8;
              font-size: get-vw-mo(26px);
              color: #666;
              &:nth-child(2) {
                margin-top: get-vw-mo(15px);
              }
            }
            .sub-tit {
              display: block;
              margin-top: get-vw-mo(50px);
              font-size: get-vw-mo(28px);
              line-height: 1.5;
            }
            .sub-txt {
              display: block;
              font-size: get-vw-mo(26px);
              line-height: 1.5;
              color: #666;
              word-break: keep-all;
            }
          }
          &:last-child{
            border-bottom: get-vw-mo(2px) solid #ccc;
          }
          &.bdb0 {
            border-bottom: 0;
          }
        }
      }
      .detail-table-wrap {
        position: relative;
        width: 100%;
        overflow-x: scroll;
        &.on {
          &:after {
            opacity: 1;
          }
        }
        &:after {
          content:"";
          display: block;
          clear: both;
          width: get-vw-mo(170px);
          height: get-vw-mo(170px);
          margin-top: get-vw-mo(-85px);
          background: url(../../images/brand/ico_swiper.png) no-repeat;
          background-size: contain;
          position: absolute;
          left: get-vw-mo(251px);
          top: 50%;
          opacity: 0;
          transition: 0.65s cubic-bezier(0.66, 0, 0.34, 1) all;
        }
      }
      table{
        position: relative;
        &.table-type01{
          width: 100%;
          margin-top: get-vw-mo(30px);
          thead{background: #f1f5f8;}
          th,td{
            min-width: get-vw-mo(230px);
            padding: get-vw-mo(25px) get-vw-mo(35px);
            text-align: center;
            color: #666;
            font-family: $NotoSansDL;
            font-size: get-vw-mo(26px);
            word-break: break-all;
            white-space: nowrap;
            border: 1px solid #ddd;
            box-sizing: border-box;
            &:first-child{border-left: none;}
            &:last-child{border-right: none;}
          }
          th{
            // font-family: $NotoSansM;
            @include notoSansM();
            color: #222;border-top: 1px solid #888;
          }
        }
      }
    }
    .precautions {
      padding: get-vw-mo(56px) 0 get-vw-mo(100px);
      text-align: center;
      .txt {
        font-size: get-vw-mo(28px);
        line-height: 1.5;
        color: #222;
      }
      .btn-download {
        display: block;
        width: get-vw-mo(300px);
        margin: get-vw-mo(20px) auto 0;
        padding: get-vw-mo(20px) 0;
        background-color: #666;
        border-radius: get-vw-mo(60px);
        .txt {
          display: inline-block;
          // font-family: $NotoSansM;
          @include notoSansM();
          font-size: get-vw-mo(30px);
          vertical-align: middle;
          color: #fff;
        }
        .ico {
          display: inline-block;
          width: get-vw-mo(23px);
          height: get-vw-mo(26px);
          margin-right: get-vw-mo(10px);
          background: url(../../images/mo/icon_down.png) no-repeat;
          background-size: cover;
          vertical-align: middle;
        }
      }
    }
  }
  .section03 {
    padding: get-vw-mo(100px) 0 get-vw-mo(180px);
    background-color: #333;
    .title-wrap {
      text-align: center;
      .related-tit {
        display: block;
        margin-bottom: get-vw-mo(40px);
        // font-family: $NotoSansM;
        @include notoSansM();
        font-size: get-vw-mo(38px);
        color: #fff;
      }
    }
    .related-swiper {
      overflow: hidden;
      .swiper-wrapper {
        display: flex;
        // justify-content: center;
        .swiper-slide {
          flex-shrink: 0;
          width: get-vw-mo(570px);
          a {
            display: block;
          }
          .img-box {
            img {
              width: 100%;
            }
          }
          .txt-box {
            margin-top: get-vw-mo(40px);
            span {
              display: block;
              text-align: center;
              &.tit {
                margin-bottom: get-vw-mo(15px);
                font-family: $NotoSansM;
                font-size: get-vw-mo(30px);
                color: #fff;
              }
              &.txt {
                font-size: get-vw-mo(28px);
                color: #999;
              }
            }
          }
          &:nth-child(1) {
            display: block;
            margin-left: 0;
          }
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .swiper-pagination {
      bottom: get-vw-mo(-100px);
      left: 0;
      right: 0;
    }
  }
}



// 태블릿
@media screen and (min-width: 751px) and (max-width: 1023px) {
  /*! 브랜드 공통 */
  .brand {
    .sec {
      padding: 150px 0;

      &.section01 {
        padding-top: 100px;
      }
    }

    // 주석 공통
    .ref-txt {
      // 서브타이틀
      top: -13px;
      left: -2px;
      font-size: 18px;
      // 대타이틀
      &.big {
        top: -40px;
        left: -2px;
        font-size: 26px;
      }
      
    }

    .title-wrap {
      .title {
        margin-bottom: 60px;
        span {
          font-size: 62px;
          em {
            font-size: 62px;
          }
        }
      }
      .tag-wrap {
        margin: 18px 0 30px;
        .tag {
          display: inline-block;
          padding: 16px 28px;
          margin-bottom: 20px;
          border-radius: 35px;
          background: #fff;
          & + .tag {
            margin-left: 15px;
          }
          font-size: 28px;
        }
      }
      .sub-tit {
        font-size: 38px;
      }
      .tit {
        width: 420px;
        margin: 30px auto 50px;
      }
      .sub-txt {
        max-width: 800px;
        span {
          font-size: 28px;
        }
      }
    }
    .mo-title {
      font-size: 28px;
      margin-bottom: 30px;
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        margin: 0 10px;
      }
    }

    //팝업
    #popup, .popup {
      &.youtube {
        .pop-wrap {
          height: 550px;
        }

      }
      .popup-close {
        top: -72px;
        right: 20px;
        .ico-close {
          width: 42px;
          height: 42px;
        }
      }
      &.pull-popup{
        .popup-inner{
          padding: 0 40px;
        }
        .popup-header{
          margin-top: 120px;
          .since-txt{
            font-size: 30px;
          }
          .tit{
            font-size: 50px;
          }
        }
        .popup-content{
          .column{
            .txt-box{
              margin-top: 50px;
              // height: 230px;
              padding-right: 30px;
              font-size: 28px;
            }
            .tag-area{
              margin-top: 40px;
              .tag {
                margin-right: 10px;
                padding:16px 28px;
                margin-bottom: 20px;
                border-radius: 35px;
                font-size: 28px;
              }
            }
            .gallery-view-area{
              margin-top: 30px;
            }
            .gallery-view-img{
              .img-item{
                padding: 30px 22px 60px;
                // img{
                //   padding-top: 30px;
                // }
              }
            }
            .gallery-btn{
              margin-top: 20px;
              .btn-item{
                width: 154px;
                margin-left:17px;
                &:nth-child(4n+1){margin-left:0px;}
              }
            }
          }
        }
      }
    }
    .ft-txt {
      padding: 30px 0;
      .noti-area {

        span {
          font-size: 24px;
          &.point-txt {
            padding-left: 12px;
            text-indent: -16px;
          }
        }

        .desc{margin-top: 20px;}
      }
    }
    .reference{
      padding: 30px 0;
      p {
        font-size: 24px;
      }
      span {
        font-size: 24px;
        a {
          font-size: 24px;
        }
      }
    }
  }

  //제품정보
  .product-info {
    .title-wrap {
      .sub-tit {
        margin-bottom: 40px;
        font-size: 30px;
      }
    }
    .section01 {
      padding-top: 100px;
      .product-swiper {
        padding-bottom: 150px;
        .swiper-wrapper {
          .swiper-slide {
            width: 554px;
            height: 554px;
            .product-cnt {
              margin-top: 10px;
              &.vertical-box {
                .img-box {
                  img {
                    margin: 0 auto;
                  }
                }
              }
              .txt {
                margin-top: 40px;
                font-size: 36px;
                &.mgt0 {
                  margin-top: -35px;
                }
                em {
                  margin-top: 4px;
                  font-size: 28px;
                }
              }
              .img-box {
                margin: auto;
              }
            }
            .shadow-box {
              left: 50%;
              width: 554px;
              height: 554px;
              margin-left: -277px;
            }
            .active-box {
              width: 554px;height: 554px;
            }
          }
        }
        .swiper-pagination {
          bottom: 110px;
        }
      }
      .swiper-pagination {
        bottom: 80px;
      }
    }
    .section02 {
      .product-detail-wrap {
        .title {
          padding: 73px 0 10px;
          .tit-box {
            .ico {
              width: 36px;
              height: 36px;
              margin-right: 10px;
            }
            .txt {
              font-size: 34px;
            }
          }
          a {
            padding: 22px 40px;
            border-radius: 40px;
            min-height: 76px;
            .ico {
              width: 23px;
              height: 26px;
              margin-right: 12px;
            }
            .txt {
              font-size: 28px;
            }
          }
        }
        .detail-wrap {
          .swiper-list {
            .detail-swiper {
              padding-bottom: 120px;
              .swiper-pagination {
                margin-top: 30px;
                span {
                  font-size: 30px;
                }
              }
              .swiper-button-next,
              .swiper-button-prev {
                width: 11px;
                height: 25px;
                bottom: 60px;
              }
            }
          }
          .detail-list {
            padding: 50px 0;
            .detail-top {
              > .ico {
                width: 97px;
                height: 87px;
                margin-right: 30px;
                &.ico01 {
                  background-size: 90px 72px;
                }
                &.ico02 {
                  background-size: 62px 82px;
                }
                &.ico03 {
                  background-size: 79px 84px;
                }
                &.ico04 {
                  background-size: 58px 84px;
                }
                &.ico05 {
                  background-size: 42px 87px;
                }
                &.ico06 {
                  background-size: 71px 64px;
                }
                &.ico07 {
                  background-size: 71px 71px;
                }
                &.ico08 {
                  background-size: 75px 75px;
                }
                &.ico10 {
                  background-size: 60px 68px;
                }
                &.ico11 {
                  background-size:55px 70px;
                }
                &.ico12 {
                  background-size:70px 72px;
                }
                &.ico14 {
                  background-size: 71px 78px;
                }
                &.ico15 {
                  background-size: 66px 66px;
                }
                &.ico16 {
                  background-size:60px 75px;
                }
              }
              .txt-box {
                width: calc(100% - 90px);
                .tit {
                  margin-bottom: 10px;
                  font-size: 30px;
                }
                .txt {
                  font-size: 28px;
                  .sub-tit {
                    font-size: 28px;
                  }
                  .sub-txt {
                    font-size: 26px;
                  }
                  
                  
                }
                
              }
            }
            .img-txt {
              margin-top: 30px;
            }
            .desc-box {
              margin-top: 30px;
              span {
                font-size: 28px;
                &.desc {
                  padding-left: 16px;
                }
              }
              .desc-t {margin-bottom: 18px;}
              .desc {
                padding-left: 16px;
                &::before {
                  top: 24px;
                  width: 4px ;
                  height: 4px;
                }
              }
            }



            .desc-box-type02 {
              .desc {
                font-size: 28px;
                padding-left: 32px;
                text-indent: -33px;
              }
            }
            .product-box {
              margin-top:40px;
              .product {
                margin-bottom: 40px;
                img {
                  margin-bottom: 25px;
                }
              }
            }
            .detail-btm {
              .box-wrap {
                margin-top: 36px;
                .cnt {
                  padding: 47px 30px 0;
                  span {
                    &.content {
                      font-size: 28px;
                    }
                    &.name {
                      .ico {
                        width: 36px;
                        height: 36px;
                        &.ico01 {
                          margin-right: 16px;
                          background-size: 36px;
                          height: 36px;
                        }
                        &.ico02 {
                          background-size: 24px;
                          height: 41px;
                        }
                        &.ico03 {
                          background-size: 28px;
                          height: 40px;
                        }
                        &.ico04 {
                          margin-right: 15px;
                          background-size: 39px;
                          height: 39px;width: 39px;
                        }
                        &.ico05 {
                          margin-right: 15px;
                          background-size: 39px;
                          height: 39px;width: 39px;
                        }
                      }
                      .txt {
                        font-size: 28px;
                      }
                    }
                  }
                  &:last-child {
                    padding-bottom: 47px;
                  }
                }

              }
              .sub-tit {
                margin-top: 50px;
                font-size: 28px;
              }
              .sub-txt {
                font-size: 26px;
              }
              .list {
                padding-left: 10px;
                text-indent: -15px;
                font-size: 26px;
              }
            }
          }
        }
        table{
          &.table-type01{
            margin-top: 30px;
            th,td{
              min-width: 230px;
              padding: 25px 35px;
              font-size: 26px;
            }
          }
          &:after {
            width:170px;
            height: 170px;
            margin-top: -85px;
            left:251px;
          }
        }
      }
      .precautions {
        padding: 56px 0 100px;
        .txt {
          font-size: 28px;
        }
        .btn-download {
          width: 300px;
          margin: 20px auto 0;
          padding: 20px 0;
          border-radius: 60px;
          .txt {
            font-size: 30px;
          }
          .ico {
            width: 23px;
            height: 26px;
            margin-right: 10px;
          }
        }
      }
    }
    .section03 {
      padding: 100px 0 180px;
      .title-wrap {
        .related-tit {
          margin-bottom: 40px;
          font-size: 38px;
        }
      }
      .related-swiper {
        .swiper-wrapper {
          .swiper-slide {
            width: 570px;
            .txt-box {
              margin-top: 40px;
              span {
                &.tit {
                  margin-bottom: 15px;
                  font-size: 30px;
                }
                &.txt {
                  font-size: 28px;
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        bottom: -100px;
      }
    }
  }


}


.ui-media-section {
  background-color: #f1f5f8;
  position: relative;
}

@media screen and (max-width: 414px) {

}

//익스
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-info {
    .section01 {
      .product-swiper {
        .swiper-wrapper {
          .swiper-slide {
            .active-box {
              left: 0;
              margin: auto;
            }
            .product-cnt {
              .img-box {
                // width: 70%;
              }
            }
          }
        }
      }
    }
  }
  .brand .title-wrap .title span {letter-spacing: -1.8px;}
  .benachio-qna .section03 .sec03-graph-wrap .cate span:nth-child(2) {margin-left: get-vw-mo(18px) !important;}
}